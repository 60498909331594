import { Dialog } from "primereact/dialog";
import { ButtonHTMLAttributes} from "react";
import styles from "./styles.module.css";

export interface AvisoOptInProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: (e: any) => void;
  modalAberto: boolean;
  setModalAberto: React.Dispatch<boolean>;
}

export const AvisoOptIn = ({
  modalAberto,
  setModalAberto,
}: AvisoOptInProps) => {
  return (
    <div className={styles.logoRedesSociais}>
      <Dialog
        header={<h1>Informações Opt In</h1>}
        className={styles.modalBase}
        visible={modalAberto}
        onHide={() => setModalAberto(false)}
        dismissableMask
        draggable
        modal
        blockScroll
        keepInViewport
        focusOnShow
      >
        <h2>
          Os dados pessoais coletados serão usados para prestação dos nossos
          serviços como, a geração de relatórios analíticos de desempenho. Os
          seus dados serão compartilhados somente com os profissionais
          envolvidos, da NetCos, da agência e anunciante contratantes. Se quiser
          saber mais, acesse a nossa{" "}
          <a
            href="https://d3encfp7yw6we0.cloudfront.net/DocumentosLegais/PoliticaDePrivacidade_22eb22bc-6625-46be-b068-2c2eb9ea6c48.pdf"
            target="_blank"
          >
            Política de Privacidade.
          </a>
        </h2>
      </Dialog>
    </div>
  );
};

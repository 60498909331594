import React, { useContext } from "react";
import { ErroType } from "../../../@types/erro";
import { tipoPublicacaoProps } from "../../../services/campanha";
import { getTodasPublicacoesDaCampanhaPorInfluenciador, postPublicacao, publicacaoProps } from "../../../services/publicacao";
import { obterTokens } from "../../../utils/LocalStorage";

import { MostrarToastContext } from "../../../context/MostrarToast";

interface FunctionsModalAdicionarPublicacaoNaTarefaProps {
  idCampanha: string;
  idTarefa: string;
  idInfluenciadorSelecionado: string;
  quantidadeTwitter: number;
  quantidadeInstagramReels: number;
  quantidadeInstagramFeed: number;
  quantidadeInstagramStories: number;
  quantidadeYouTube: number;
  quantidadeYouTubeShorts: number;
  quantidadeTiktok: number;
  setQuantidadeTwitter: React.Dispatch<React.SetStateAction<number>>;
  setQuantidadeInstagramReels: React.Dispatch<React.SetStateAction<number>>;
  setQuantidadeInstagramFeed: React.Dispatch<React.SetStateAction<number>>;
  setQuantidadeInstagramStories: React.Dispatch<React.SetStateAction<number>>;
  setQuantidadeYouTube: React.Dispatch<React.SetStateAction<number>>;
  setQuantidadeYouTubeShorts: React.Dispatch<React.SetStateAction<number>>;
  setQuantidadeTiktok: React.Dispatch<React.SetStateAction<number>>;
  setPublicacao: React.Dispatch<React.SetStateAction<publicacaoProps[]>>;
  setErro: React.Dispatch<React.SetStateAction<ErroType>>;
  modalAberto: boolean;
  setModalAberto: React.Dispatch<React.SetStateAction<boolean>>;
  setRecarregar: () => void;
  setCarregando: React.Dispatch<boolean>;
  myToast: React.MutableRefObject<any>;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

let quantidadePublicacoesInstagramFeed: number;
let quantidadePublicacoesInstagramReels: number;
let quantidadePublicacoesInstagramStories: number;
let quantidadePublicacoesTikTok: number;
let quantidadePublicacoesTwitter: number;
let quantidadePublicacoesYouTube: number;
let quantidadePublicacoesYouTubeShorts: number;

export const FunctionsModalAdicionarPublicacaoNaTarefa = ({
  idCampanha,
  idTarefa,
  idInfluenciadorSelecionado,
  quantidadeTwitter,
  quantidadeInstagramReels,
  quantidadeInstagramFeed,
  quantidadeInstagramStories,
  quantidadeYouTube,
  quantidadeYouTubeShorts,
  quantidadeTiktok,
  setQuantidadeTwitter,
  setQuantidadeInstagramReels,
  setQuantidadeInstagramFeed,
  setQuantidadeInstagramStories,
  setQuantidadeYouTube,
  setQuantidadeYouTubeShorts,
  setQuantidadeTiktok,
  setPublicacao,
  setErro,
  modalAberto,
  setModalAberto,
  setCarregando,
  adicionarAListaEsperaRenovacaoToken,
  setRecarregar,
  myToast,
}: FunctionsModalAdicionarPublicacaoNaTarefaProps) => {
  const { mostrarToast } = useContext(MostrarToastContext);

  const obterQuantidadeDePublicacoesDoInflu = () => {
    setErro({ ativo: false, mensagem: "" });
    setCarregando(true);
    const { token, tokenRenovacao } = obterTokens();

    getTodasPublicacoesDaCampanhaPorInfluenciador(
      token,
      idCampanha,
      idInfluenciadorSelecionado,
      "Ascending",
      "Nome",
      1,
      10000
    )
      .then((res) => {
        quantidadePublicacoesInstagramFeed = res.data.tarefas.filter((e) => e.tipo == "PublicacaoInstagramFeed").length;
        quantidadePublicacoesInstagramReels = res.data.tarefas.filter((e) => e.tipo == "PublicacaoInstagramReels").length;
        quantidadePublicacoesInstagramStories = res.data.tarefas.filter((e) => e.tipo == "PublicacaoInstagramStories").length;
        quantidadePublicacoesTikTok = res.data.tarefas.filter((e) => e.tipo == "PublicacaoTikTok").length;
        quantidadePublicacoesTwitter = res.data.tarefas.filter((e) => e.tipo == "PublicacaoTwitter").length;
        quantidadePublicacoesYouTube = res.data.tarefas.filter((e) => e.tipo == "PublicacaoYouTube").length;
        quantidadePublicacoesYouTubeShorts = res.data.tarefas.filter((e) => e.tipo == "PublicacaoYouTubeShorts").length;
      })
      .catch((err) => {
        if (err.message.includes('401')) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            getTodasPublicacoesDaCampanhaPorInfluenciador(
              token,
              idCampanha,
              idInfluenciadorSelecionado,
              "Ascending",
              "Nome",
              1,
              10000
            )
              .then((res) => {
                quantidadePublicacoesInstagramFeed = res.data.tarefas.filter((e) => e.tipo == "PublicacaoInstagramFeed").length;
                quantidadePublicacoesInstagramReels = res.data.tarefas.filter((e) => e.tipo == "PublicacaoInstagramReels").length;
                quantidadePublicacoesInstagramStories = res.data.tarefas.filter((e) => e.tipo == "PublicacaoInstagramStories").length;
                quantidadePublicacoesTikTok = res.data.tarefas.filter((e) => e.tipo == "PublicacaoTikTok").length;
                quantidadePublicacoesTwitter = res.data.tarefas.filter((e) => e.tipo == "PublicacaoTwitter").length;
                quantidadePublicacoesYouTube = res.data.tarefas.filter((e) => e.tipo == "PublicacaoYouTube").length;
                quantidadePublicacoesYouTubeShorts = res.data.tarefas.filter((e) => e.tipo == "PublicacaoYouTubeShorts").length;
              })
              .catch(() => {
                setErro({
                  ativo: true,
                  mensagem: "Erro ao carregar a quantidade de Publicações.",
                });
              });
          }, setCarregando)
        } else {
          setErro({
            ativo: true,
            mensagem: "Erro ao carregar a quantidade de Publicações.",
          });
        }
      })
      .finally(() => {
        setCarregando(false);
      });
  };

  function setaPublicacoes() {
    let publicacoesArray: {
      numeroIdentificadorDePublicacao: number;
      tipoPublicacao: tipoPublicacaoProps;
      dataFinalizacao: string;
      sentimento: string;
    }[] = [];

    console.log("quantidadePublicacoesTikTok para null: " + quantidadePublicacoesTikTok);


    for (let i = 0; i < quantidadeTwitter; i++) {
      publicacoesArray.push({
        numeroIdentificadorDePublicacao: quantidadePublicacoesTwitter + i,
        tipoPublicacao: "PublicacaoTwitter",
        dataFinalizacao: new Date().toISOString(),
        sentimento: "Pendente",
      });
    }
    for (let i = 0; i < quantidadeInstagramFeed; i++) {
      publicacoesArray.push({
        numeroIdentificadorDePublicacao: quantidadePublicacoesInstagramFeed + i,
        tipoPublicacao: "PublicacaoInstagramFeed",
        dataFinalizacao: new Date().toISOString(),
        sentimento: "Pendente",
      });
    }
    for (let i = 0; i < quantidadeInstagramReels; i++) {
      publicacoesArray.push({
        numeroIdentificadorDePublicacao: quantidadePublicacoesInstagramReels + i,
        tipoPublicacao: "PublicacaoInstagramReels",
        dataFinalizacao: new Date().toISOString(),
        sentimento: "Pendente",
      });
    }
    for (let i = 0; i < quantidadeInstagramStories; i++) {
      publicacoesArray.push({
        numeroIdentificadorDePublicacao: quantidadePublicacoesInstagramStories + i,
        tipoPublicacao: "PublicacaoInstagramStories",
        dataFinalizacao: new Date().toISOString(),
        sentimento: "Pendente",
      });
    }
    for (let i = 0; i < quantidadeTiktok; i++) {
      publicacoesArray.push({
        numeroIdentificadorDePublicacao: quantidadePublicacoesTikTok + i,
        tipoPublicacao: "PublicacaoTikTok",
        dataFinalizacao: new Date().toISOString(),
        sentimento: "Pendente",
      });
    }
    for (let i = 0; i < quantidadeYouTube; i++) {
      publicacoesArray.push({
        numeroIdentificadorDePublicacao: quantidadePublicacoesYouTube + i,
        tipoPublicacao: "PublicacaoYouTube",
        dataFinalizacao: new Date().toISOString(),
        sentimento: "Pendente",
      });
    }
    for (let i = 0; i < quantidadeYouTubeShorts; i++) {
      publicacoesArray.push({
        numeroIdentificadorDePublicacao: quantidadePublicacoesYouTubeShorts + i,
        tipoPublicacao: "PublicacaoYouTubeShorts",
        dataFinalizacao: new Date().toISOString(),
        sentimento: "Pendente",
      });
    }

    return publicacoesArray;
  }

  function cadastrarPublicacaoNaTarefa(publicacao: publicacaoProps[]) {
    setErro({ ativo: false, mensagem: "" });
    const sucessoRequisicao = () => {
      mostrarToast(
        "success",
        "cadastro realizado",
        "Publicação adicionada com sucesso!"
      );
      setRecarregar();
      setModalAberto(!modalAberto);
      limpaStates();
    };

    const erroRequisicao = () => {
      mostrarToast(
        "error",
        "cadastro não realizado",
        "Não foi possível adicionar a publicação."
      );
    };

    setCarregando(true);
    const { token, tokenRenovacao } = obterTokens();

    postPublicacao(token, idCampanha, idTarefa, publicacao)
      .then(() => {
        sucessoRequisicao();
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await postPublicacao(token, idCampanha, idTarefa, publicacao)
              .then(() => {
                sucessoRequisicao();
              })
              .catch(() => {
                erroRequisicao();
              });
          }, setCarregando);
        } else {
          erroRequisicao();
          setCarregando(false);
        }
      });
  }

  function limpaStates() {
    setQuantidadeTwitter(0);
    setQuantidadeInstagramReels(0);
    setQuantidadeInstagramFeed(0);
    setQuantidadeInstagramStories(0);
    setQuantidadeYouTube(0);
    setQuantidadeYouTubeShorts(0);
    setQuantidadeTiktok(0);
  }

  return {
    setaPublicacoes,
    cadastrarPublicacaoNaTarefa,
    obterQuantidadeDePublicacoesDoInflu
  };
};

import { useContext } from "react";
import { MostrarToastContext } from "../../../context/MostrarToast";
import { deleteTarefa } from "../../../services/tarefas";
import { obterTokens } from "../../../utils/LocalStorage";

interface deletarInfluenciadorDaTarefaProps {
  idCampanha: string;
  idTarefa: string;
  setRecarregar?: React.Dispatch<React.SetStateAction<boolean>>;
  setCarregando?: React.Dispatch<React.SetStateAction<boolean>>;
  setModalDesativacao: React.Dispatch<React.SetStateAction<boolean>>;
  recarregarTabela: () => void;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FunctionDeletarInfluenciadorDaTarefa = ({
  setCarregando,
  setRecarregar,
  setModalDesativacao,
  recarregarTabela,
  adicionarAListaEsperaRenovacaoToken,
}: deletarInfluenciadorDaTarefaProps) => {
  const { mostrarToast } = useContext(MostrarToastContext);

  const sucessoRequisicao = () => {
    setRecarregar && setRecarregar((recarregar) => !recarregar);
    recarregarTabela();
    mostrarToast(
      "success",
      "Influenciadores removido!",
      "Influenciadores removido com sucesso!"
    );
  };

  const erroRequisicao = () => {
    mostrarToast(
      "error",
      "Remoção não realizado",
      "Não foi possível remover o influenciador."
    );
  };

  function deletarInfluenciadorNaTarefa(idCampanha: string, idTarefa: string) {
    const { token } = obterTokens();
    setCarregando && setCarregando(true);
    deleteTarefa(token, idCampanha, idTarefa)
      .then(() => {
        sucessoRequisicao();
        setCarregando && setCarregando(false);
        setModalDesativacao(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(
            async (token) => {
              await deleteTarefa(token, idCampanha, idTarefa)
                .then(() => {
                  sucessoRequisicao();
                  setModalDesativacao(false);
                })
                .catch(() => {
                  erroRequisicao();
                });
            },
            () => setCarregando && setCarregando(false)
          );
        } else {
          erroRequisicao();
          setCarregando && setCarregando(false);
        }
      });
  }
  return {
    deletarInfluenciadorNaTarefa,
  };
};

import { useContext } from "react";

import {
  getInfluenciadorProps,
  getInfluenciadores,
  postInfluenciador,
} from "../../../services/influenciador";

import { converteImgPara64 } from "../../../utils/ConverteImgPara64";
import { obterTokens } from "../../../utils/LocalStorage";
import { MascaraTelefone } from "../../../utils/MascaraTelefone";

import { MostrarToastContext } from "../../../context/MostrarToast";
import { tipoOrdenacao } from "../../../@types/ordenacao";
import { ErroType } from "../../../@types/erro";

interface FunctionsModalCadastroInfluenciadorProps {
  nomeInfluenciador: string | undefined;
  nome: string;
  emailInfluenciador: string;
  perfilInfluenciador: string;
  imagemAvatar: string;
  setImagemAvatar: React.Dispatch<React.SetStateAction<string>>;
  telefone: number;
  setTelefone: React.Dispatch<React.SetStateAction<number>>;
  setCarregando: React.Dispatch<boolean>;
  setModalAberto: React.Dispatch<boolean>;
  MascaraNumeroWhatsapp: string;
  setMascaraNumeroWhatsapp: React.Dispatch<React.SetStateAction<string>>;
  enviarNotificacaoDeCadastro: boolean;
  setEnviarNotificacaoDeCadastro: React.Dispatch<React.SetStateAction<boolean>>;
  myToast: React.MutableRefObject<any>;
  setRecarregar?: React.Dispatch<React.SetStateAction<boolean>>;

  setIdInfluenciador?: React.Dispatch<React.SetStateAction<string>>;
  setNomeInfluenciador: React.Dispatch<React.SetStateAction<string>>;
  setEmailInfluenciador: React.Dispatch<React.SetStateAction<string>>;
  setPerfilInfluenciador: React.Dispatch<React.SetStateAction<string>>;
  numeroPagina: number;
  tamanhoPagina: number;
  tipoOrdenacao: tipoOrdenacao;
  nomePropriedadeOrdenacao: string;
  setErro: React.Dispatch<React.SetStateAction<ErroType>>;

  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FunctionsModalCadastroInfluenciador = ({
  nomeInfluenciador,
  nome,
  emailInfluenciador,
  perfilInfluenciador,
  imagemAvatar,
  setImagemAvatar,
  telefone,
  setTelefone,
  enviarNotificacaoDeCadastro,
  setEnviarNotificacaoDeCadastro,
  myToast,
  MascaraNumeroWhatsapp,
  setMascaraNumeroWhatsapp,
  setCarregando,
  setModalAberto,
  adicionarAListaEsperaRenovacaoToken,
  setRecarregar,
  setIdInfluenciador,
  setNomeInfluenciador,
  setEmailInfluenciador,
  setPerfilInfluenciador,
  nomePropriedadeOrdenacao,
  numeroPagina,
  tamanhoPagina,
  tipoOrdenacao,
  setErro,
}: FunctionsModalCadastroInfluenciadorProps) => {
  const { mostrarToast } = useContext(MostrarToastContext);

  const sucessoRequisicao = () => {
    mostrarToast(
      "success",
      "cadastro realizado",
      "Influenciador cadastrado com sucesso!"
    );

    setRecarregar && setRecarregar((e) => !e);
    setModalAberto(false);
  };

  const erroRequisicao = (mensagemApi: string) => {
    let mensagem: string = "";

    switch (mensagemApi) {
      case "User name '' is invalid, can only contain letters or digits.":
        mensagem = "Preencha todos os campos e tente novamente.";
        break;
      case "Must specify valid information for parsing in the string. (Parameter \u0027value\u0027)":
        mensagem = "Selecione o perfil do influenciador e tente novamente.";
        break;
      case "Influenciador já existe em nossa base de dados":
        mensagem = "Email já cadastrado no sistema";
        break;
      default:
        mensagem =
          "Não foi possível cadastrar usuário, tente novamente mais tarde.";
    }

    showToast("error", "cadastro não realizado", mensagem);
  };

  const pegaIdInfluenciadorParaOSelect = () => {
    const { token, tokenRenovacao } = obterTokens();
    getInfluenciadores(
      token,
      numeroPagina,
      tamanhoPagina,
      tipoOrdenacao,
      nomePropriedadeOrdenacao
    )
      .then((res) => {
        let influenciador: getInfluenciadorProps =
          res.data.influenciadores.find(
            (influenciador: getInfluenciadorProps) =>
              influenciador.email === emailInfluenciador
          );

        setIdInfluenciador && setIdInfluenciador(influenciador.idCredenciais);
        setNomeInfluenciador(influenciador.nome);
        setEmailInfluenciador && setEmailInfluenciador(influenciador.email);
        setPerfilInfluenciador && setPerfilInfluenciador(influenciador.perfil);

        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await getInfluenciadores(
              token,
              numeroPagina,
              tamanhoPagina,
              tipoOrdenacao,
              nomePropriedadeOrdenacao
            )
              .then((res) => {
                let influenciador = res.data.find(
                  (influenciador: getInfluenciadorProps) =>
                    influenciador.email === emailInfluenciador
                );

                setIdInfluenciador &&
                  setIdInfluenciador(influenciador.idCredenciais);
                setNomeInfluenciador(influenciador.nome);
                setEmailInfluenciador &&
                  setEmailInfluenciador(influenciador.email);
                setPerfilInfluenciador &&
                  setPerfilInfluenciador(influenciador.perfil);
              })
              .catch(() => {
                setErro({
                  ativo: true,
                  mensagem: "Erro ao carregar a lista de influenciadores.",
                });
              });
          }, setCarregando);
        } else {
          setErro({
            ativo: true,
            mensagem: "Erro ao carregar a lista de influenciadores.",
          });
          setCarregando(false);
        }
      });
  };

  const salvarInfluenciador = () => {
    setCarregando(true);

    const { token, tokenRenovacao } = obterTokens();

    postInfluenciador(
      token,
      nomeInfluenciador ? nomeInfluenciador : nome,
      emailInfluenciador,
      imagemAvatar,
      MascaraNumeroWhatsapp,
      perfilInfluenciador,
      "Pendente",
      enviarNotificacaoDeCadastro
    )
      .then(() => {
        setEnviarNotificacaoDeCadastro(false);
        sucessoRequisicao();
      })
      .then(() => {
        pegaIdInfluenciadorParaOSelect();
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await postInfluenciador(
              token,
              nomeInfluenciador ? nomeInfluenciador : nome,
              emailInfluenciador,
              imagemAvatar,
              MascaraNumeroWhatsapp,
              perfilInfluenciador,
              "Pendente",
              enviarNotificacaoDeCadastro
            )
              .then(() => {
                setEnviarNotificacaoDeCadastro(false);
                sucessoRequisicao();
              })
              .then(() => {
                pegaIdInfluenciadorParaOSelect();
              })
              .catch(() => {
                erroRequisicao(err.response.data.mensagem);
              });
          }, setCarregando);
        } else {
          erroRequisicao(
            err.response.data.mensagem
              ? err.response.data.mensagem
              : err.response.data.message
          );
          setCarregando(false);
        }
      });
  };

  function fazConverteImgPara64(event: any) {
    converteImgPara64(event, setImagemAvatar);
  }

  function fazMascaraTelefone(telefone: string) {
    MascaraTelefone(telefone, setTelefone, setMascaraNumeroWhatsapp);
  }

  
  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string
  ) => {
    myToast.current.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  return {
    converteImgPara64,
    fazMascaraTelefone,
    salvarInfluenciador,
    showToast,
  };
};

import { useContext } from "react";

import { postUsuarioNetcos } from "../../../services/usuarioNetcos";

import { converteImgPara64 } from "../../../utils/ConverteImgPara64";
import { LimparStates } from "../../../utils/LimparStates";
import { obterTokens } from "../../../utils/LocalStorage";
import { MascaraTelefone } from "../../../utils/MascaraTelefone";

import { MostrarToastContext } from "../../../context/MostrarToast";

export interface FuncoesUsuarioNetcosProps {
  nome: string;
  setNome: React.Dispatch<React.SetStateAction<string>>;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  imagemAvatar: string;
  setImagemAvatar: React.Dispatch<React.SetStateAction<string>>;
  telefone: number;
  setTelefone: React.Dispatch<React.SetStateAction<number>>;
  MascaraNumeroWhatsapp: string;
  setMascaraNumeroWhatsapp: React.Dispatch<React.SetStateAction<string>>;
  setSucesso: React.Dispatch<React.SetStateAction<boolean>>;
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>;
  setRecarregar: () => void;
  setModalAberto: React.Dispatch<boolean>;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
  myToast: React.MutableRefObject<any>;
}

export const FuncoesCadastroFuncionario = ({
  nome,
  setNome,
  email,
  setEmail,
  imagemAvatar,
  setImagemAvatar,
  telefone,
  setTelefone,
  MascaraNumeroWhatsapp,
  setMascaraNumeroWhatsapp,
  setSucesso,
  setCarregando,
  setRecarregar,
  setModalAberto,
  myToast,
  adicionarAListaEsperaRenovacaoToken,
}: FuncoesUsuarioNetcosProps) => {
  const { mostrarToast } = useContext(MostrarToastContext);

  const sucessoRequisicao = () => {
    mostrarToast(
      "success",
      "cadastro realizado",
      "Usuário cadastrado com sucesso!"
    );

    setRecarregar();
    setModalAberto(false);
  };

  const erroRequisicao = (mensagemApi: string) => {
    let mensagem: string = "";

    switch (mensagemApi) {
      case "User name '' is invalid, can only contain letters or digits.":
        mensagem = "Preencha todos os campos e tente novamente.";
        break;
      case "Este e-mail já está sendo usado.":
        mensagem = "Email já cadastrado no sistema";
        break;
      case "O nome é obrigatório.":
        mensagem = "O nome é obrigatório.";
        break;

      default:
        mensagem =
          "Não foi possível cadastrar usuário, tente novamente mais tarde.";
    }

    showToast("error", "Cadastro não realizado", mensagem);
  };

  function cadastrarUsuarioNetcos() {
    const { token, tokenRenovacao } = obterTokens();
    setCarregando(true);
    postUsuarioNetcos(
      token,
      {
        nome,
        email,
        imagemAvatar,
        telefone: MascaraNumeroWhatsapp,
        status: "Pendente",
      },
    )
      .then(() => {
        LimparStates([
          setEmail,
          setNome,
          setImagemAvatar,
          setMascaraNumeroWhatsapp,
        ]);
        setSucesso(true);
        sucessoRequisicao();
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await postUsuarioNetcos(
              token,
              {
                nome,
                email,
                imagemAvatar,
                telefone: MascaraNumeroWhatsapp,
                status: "Pendente",
              },
            )
              .then(() => {
                LimparStates([
                  setEmail,
                  setNome,
                  setImagemAvatar,
                  setMascaraNumeroWhatsapp,
                ]);
                setSucesso(true);
                sucessoRequisicao();
              })
              .catch((err) => {
                setSucesso(false);
                erroRequisicao(err.response.data.message);
              });
          }, setCarregando);
        } else {
          setSucesso(false);
          erroRequisicao(err.response.data.message);
          setCarregando(false);
          
        }
      });
  }

  function fazMascaraTelefone(telefone: string) {
    MascaraTelefone(telefone, setTelefone, setMascaraNumeroWhatsapp);
  }

  function confirmarCadastro(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    cadastrarUsuarioNetcos();
  }

  function fazConverteImgPara64(event: any) {
    converteImgPara64(event, setImagemAvatar);
  }

  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string
  ) => {
    myToast.current.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  return {
    cadastrarUsuarioNetcos,
    fazMascaraTelefone,
    confirmarCadastro,
    fazConverteImgPara64,
    showToast,
  };
};

import { useContext } from "react";

import { obterTokens } from "../../../utils/LocalStorage";

import { MostrarToastContext } from "../../../context/MostrarToast";
import {
  destinatariosExtrasProps,
  putCampanhaEnviarRelatorio,
} from "../../../services/campanha";

interface FunctionsModalEnviarRelatorioProps {
  setCarregando: React.Dispatch<boolean>;
  myToast: React.MutableRefObject<any>;
  setModalAberto: React.Dispatch<boolean>;
  gerarRelatorio: (baixarRelatorio: boolean) => Promise<string>;
  idCampanha: string;
  enviarParaUsuariosMarca: boolean;
  enviarParaUsuariosAgencia: boolean;
  destinatariosExtras: string[];
  setDestinatariosExtras: React.Dispatch<
    React.SetStateAction<string[]>
  >;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  email: string;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FunctionsModalEnviarRelatorio = ({
  myToast,
  setModalAberto,
  setCarregando,
  gerarRelatorio,
  destinatariosExtras,
  enviarParaUsuariosAgencia,
  enviarParaUsuariosMarca,
  idCampanha,
  setDestinatariosExtras,
  email,
  setEmail,
  adicionarAListaEsperaRenovacaoToken,
}: FunctionsModalEnviarRelatorioProps) => {
  const { mostrarToast } = useContext(MostrarToastContext);

  const sucessoRequisicao = () => {
    mostrarToast(
      "success",
      "Relatório Enviado!",
      "Relatório enviado a todos os emails requeridos!"
    );
    setModalAberto(false);
  };

  const erroRequisicao = (mensagemApi: string) => {
    let mensagem: string = "Ocorreu um erro ao enviar o relatório.";

    switch (mensagemApi) {
      case "User name '' is invalid, can only contain letters or digits.":
        mensagem = "Preencha todos os campos e tente novamente.";
        break;
      case "Este e-mail já está sendo usado.":
        mensagem = "Email já cadastrado no sistema";
        break;

      default:
        mensagem =
          "Não foi possível cadastrar usuário, tente novamente mais tarde.";
    }

    mostrarToast("error", "Relatório não enviado.", mensagem);
  };

  const enviaRelatorio = async () => {
    setCarregando(true);
    const { token } = obterTokens();
    let arquivoRelatorio = await gerarRelatorio(false);

    putCampanhaEnviarRelatorio({
      token,
      arquivoRelatorio,
      destinatariosExtras,
      enviarParaUsuariosAgencia,
      enviarParaUsuariosMarca,
      idCampanha,
    })
      .then(() => {
        sucessoRequisicao();
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await putCampanhaEnviarRelatorio({
              token,
              arquivoRelatorio,
              destinatariosExtras,
              enviarParaUsuariosAgencia,
              enviarParaUsuariosMarca,
              idCampanha,
            })
              .then(() => {
                setDestinatariosExtras([]);
                sucessoRequisicao();
              })
              .catch(() => {
                erroRequisicao(err.response.data.mensagem);
              });
          }, setCarregando);
        } else {
          erroRequisicao(err.response.data.mensagem);
          setCarregando(false);
        }
      });
  };

  function adicionaEmailALista() {
    let listaProvisoria: string[] = destinatariosExtras;

    listaProvisoria.push(email);

    // listaProvisoria.push({
    //   email: email,
    //   name: email + "+" + new Date().getTime(),
    // });

    setEmail("");

    setDestinatariosExtras(listaProvisoria);
  }

  function retiraEmailDaLista(nomeARetirar: string) {
    let listaProvisoria = destinatariosExtras.filter(
      (destinatario) => destinatario !== nomeARetirar
    );

    setDestinatariosExtras(listaProvisoria);
  }

  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string
  ) => {
    myToast.current.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  return {
    enviaRelatorio,
    showToast,
    adicionaEmailALista,
    retiraEmailDaLista,
  };
};

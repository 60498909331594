import { useContext } from "react";

import { postMarca } from "../../../services/marca";
import { LimparStates } from "../../../utils/LimparStates";
import { obterTokens } from "../../../utils/LocalStorage";

import { MostrarToastContext } from "../../../context/MostrarToast";

interface FunctionsBotaoCadastroMarcaProps {
  nome: string;
  setNome: React.Dispatch<React.SetStateAction<string>>;
  logotipo: string;
  setLogotipo: React.Dispatch<React.SetStateAction<string>>;
  setCarregando: React.Dispatch<boolean>;
  setRecarregar: React.Dispatch<React.SetStateAction<boolean>>;
  botaoAbertoMarca: boolean;
  setBotaoAbertoMarca: React.Dispatch<React.SetStateAction<boolean>>;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FunctionsBotaoCadastroMarca = ({
  logotipo,
  setLogotipo,
  nome,
  setNome,
  setCarregando,
  setRecarregar,
  setBotaoAbertoMarca,
  adicionarAListaEsperaRenovacaoToken,
}: FunctionsBotaoCadastroMarcaProps) => {
  const { mostrarToast } = useContext(MostrarToastContext);

  const sucessoRequisicao = () => {
    setRecarregar && setRecarregar((recarregar) => !recarregar);
    mostrarToast(
      "success",
      "cadastro realizado",
      "Marca cadastrada com sucesso!"
    );
    setTimeout(() => setBotaoAbertoMarca(false), 1000);
    LimparStates([setNome, setLogotipo]);
  };

  const erroRequisicao = () => {
    mostrarToast(
      "error",
      "cadastro não realizado",
      "Não foi possível cadastrar a Marca."
    );
  };

  const erroMarcaSemNome = () => {
    setCarregando(false);
    mostrarToast(
      "error",
      "cadastro não realizado",
      "Não é possível cadastrar uma Marca sem nome."
    );
  };

  const salvarMarcaButton = () => {
    setCarregando(true);
    const { token } = obterTokens();

    nome
      ? postMarca(token, { nome, logotipo })
          .then(() => {
            sucessoRequisicao();
            setCarregando(false);
          })
          .catch((err) => {
            console.log(err.mensage);
            if (err.message.includes("401")) {
              adicionarAListaEsperaRenovacaoToken(async (token) => {
                await postMarca(token, { nome, logotipo })
                  .then(() => {
                    sucessoRequisicao();
                  })
                  .catch(() => {
                    erroRequisicao();
                  });
              }, setCarregando);
            } else {
              erroRequisicao();
              setCarregando(false);
            }
          })
      : erroMarcaSemNome();
  };

  return {
    salvarMarcaButton,
  };
};

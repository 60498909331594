import { useContext } from "react";

import { postUsuarioMarca } from "../../../services/usuarioMarca";
import { converteImgPara64 } from "../../../utils/ConverteImgPara64";
import { LimparStates } from "../../../utils/LimparStates";
import { obterTokens } from "../../../utils/LocalStorage";
import { MascaraTelefone } from "../../../utils/MascaraTelefone";
import { MostrarToastContext } from "../../../context/MostrarToast";

interface FunctionsModalCadastroUsuarioMarcaProps {
  nome: string;
  setNome: React.Dispatch<React.SetStateAction<string>>;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  imagemAvatar: string;
  setImagemAvatar: React.Dispatch<React.SetStateAction<string>>;
  telefone: number;
  setTelefone: React.Dispatch<React.SetStateAction<number>>;
  idMarca: string;
  setIdMarca: React.Dispatch<React.SetStateAction<string>>;
  enviarNotificacaoDeCadastro: boolean;
  setEnviarNotificacaoDeCadastro: React.Dispatch<React.SetStateAction<boolean>>;
  setCarregando: React.Dispatch<boolean>;
  MascaraNumeroWhatsapp: string;
  setMascaraNumeroWhatsapp: React.Dispatch<React.SetStateAction<string>>;
  myToast: React.MutableRefObject<any>;
  setRecarregar?: () => void;
  setModalAberto: React.Dispatch<boolean>;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FunctionsModalCadastroUsuarioMarca = ({
  nome,
  setNome,
  email,
  setEmail,
  imagemAvatar,
  setImagemAvatar,
  telefone,
  setTelefone,
  idMarca,
  setIdMarca,
  enviarNotificacaoDeCadastro,
  setEnviarNotificacaoDeCadastro,
  myToast,
  MascaraNumeroWhatsapp,
  setMascaraNumeroWhatsapp,
  setCarregando,
  adicionarAListaEsperaRenovacaoToken,
  setRecarregar,
  setModalAberto,
}: FunctionsModalCadastroUsuarioMarcaProps) => {
  const { mostrarToast } = useContext(MostrarToastContext);

  const sucessoRequisicao = () => {
    mostrarToast(
      "success",
      "cadastro realizado",
      "Usuário cadastrada com sucesso!"
    );
    setRecarregar && setRecarregar();
    setModalAberto(false);
  };

  const erroRequisicao = (mensagemApi: string) => {
    let mensagem: string = "";

    switch (mensagemApi) {
      case "User name '' is invalid, can only contain letters or digits.":
        mensagem = "Preencha todos os campos e tente novamente.";
        break;
      case "Este e-mail já está sendo usado.":
        mensagem = "Email já cadastrado no sistema";
        break;
      case "O nome é obrigatório.":
        mensagem = "O nome é obrigatório.";
        break;

      default:
        mensagem =
          "Não foi possível cadastrar usuário, tente novamente mais tarde.";
    }

    showToast("error", "Cadastro não realizado", mensagem);
  };

  const salvarUsuarioMarca = () => {
    setCarregando(true);

    const { token, tokenRenovacao } = obterTokens();
    postUsuarioMarca(
      token,
      {
        nome,
        email,
        imagemAvatar: imagemAvatar ? imagemAvatar : "",
        telefone: MascaraNumeroWhatsapp,
        idMarca,
        status: "Pendente",
      },
      enviarNotificacaoDeCadastro
    )
      .then(() => {
        LimparStates([
          setIdMarca,
          setEmail,
          setNome,
          setImagemAvatar,
          setMascaraNumeroWhatsapp,
        ]);
        setTelefone(0);
        setEnviarNotificacaoDeCadastro(false);
        setRecarregar && setRecarregar();
        sucessoRequisicao();
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await postUsuarioMarca(
              token,
              {
                nome,
                email,
                imagemAvatar: imagemAvatar ? imagemAvatar : "",
                telefone: MascaraNumeroWhatsapp,
                idMarca,
                status: "Pendente",
              },
              enviarNotificacaoDeCadastro
            )
              .then(() => {
                LimparStates([
                  setIdMarca,
                  setEmail,
                  setNome,
                  setImagemAvatar,
                  setMascaraNumeroWhatsapp,
                ]);
                setTelefone(0);
                setEnviarNotificacaoDeCadastro(false);
                sucessoRequisicao();
              })
              .catch(() => {
                erroRequisicao(err.response.data.message);
              });
          }, setCarregando);
        } else {
          erroRequisicao(err.response.data.message);
          setCarregando(false);
        }
      });
  };

  function fazConverteImgPara64(event: any) {
    converteImgPara64(event, setImagemAvatar);
  }

  function fazMascaraTelefone(telefone: string) {
    MascaraTelefone(telefone, setTelefone, setMascaraNumeroWhatsapp);
  }

  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string
  ) => {
    myToast.current.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  return {
    converteImgPara64,
    fazMascaraTelefone,
    salvarUsuarioMarca,
    showToast,
  };
};

import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CredenciaisContext } from "../../context/CredenciaisContext";
import { obterCredenciais } from "../../utils/LocalStorage";
import { DrawerNavbar } from "../Drawers/DrawerNavbar";

import { Avatar } from "primereact/avatar";
import styles from "./styles.module.css";

export const Navbar = () => {
  const credenciais = obterCredenciais().credenciais;
  const avatar = credenciais.urlAvatar;
  const nome = credenciais.nome;
  const perfil = useContext(CredenciaisContext).credenciais.perfil[0];
  const removerCredenciais = useContext(CredenciaisContext).removerCredenciais;
  const navigate = useNavigate();

  return (
    <>
      <nav className={styles.menu}>
        <DrawerNavbar />
        <ul className={styles.menuLista}>
          <li
            className={styles.menuListaItem}
            title="Exibir Perfil"
            onClick={() => navigate("/perfil-usuario")}
          >
            <p>{nome}</p>
            <Avatar
              image={avatar ? avatar : undefined}
              shape="circle"
              size="large"
              icon="pi pi-user"
            />
          </li>

          <li
            className={styles.menuListaItem}
            title="Sair"
            onClick={() => {
              removerCredenciais();
              navigate("/");
            }}
          >
            <i className="pi pi-sign-out" style={{ fontSize: "1.4rem" }}></i>
            <a>Sair</a>
          </li>
        </ul>
      </nav>
    </>
  );
};

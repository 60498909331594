import { useContext } from "react";

import { MostrarToastContext } from "../../../context/MostrarToast";
import { putInfluenciador } from "../../../services/influenciador";
import { editarEmailDoUsuario } from "../../../services/usuario";
import { converteImgPara64 } from "../../../utils/ConverteImgPara64";
import { LimparStates } from "../../../utils/LimparStates";
import { obterTokens } from "../../../utils/LocalStorage";
import { MascaraTelefone } from "../../../utils/MascaraTelefone";

interface FuncoesModalAtualizarInfluenciadorProps {
  idCredenciais: string;
  nome: string;
  setNome: React.Dispatch<React.SetStateAction<string>>;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  telefone: number;
  setTelefone: React.Dispatch<React.SetStateAction<number>>;
  MascaraNumeroWhatsapp: string;
  setMascaraNumeroWhatsapp: React.Dispatch<React.SetStateAction<string>>;
  imagemAvatar: string | null;
  setImagemAvatar: React.Dispatch<React.SetStateAction<string>>;
  perfil: string;
  setPerfil: React.Dispatch<React.SetStateAction<string>>;
  status: string | null;
  linkInstagram: string;
  linkTwitter: string;
  linkTiktok: string;
  linkYouTube: string;
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>;
  setModalAberto: React.Dispatch<boolean>;
  setRecarregar: () => void;
  emailPrevio: string;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FunctionsModalAtualizarInfluenciador = ({
  idCredenciais,
  nome,
  setNome,
  email,
  imagemAvatar,
  setImagemAvatar,
  telefone,
  setTelefone,
  perfil,
  setPerfil,
  status,
  linkTwitter,
  linkInstagram,
  linkTiktok,
  linkYouTube,
  MascaraNumeroWhatsapp,
  setMascaraNumeroWhatsapp,
  setCarregando,
  setModalAberto,
  setRecarregar,
  emailPrevio,
  adicionarAListaEsperaRenovacaoToken,
}: FuncoesModalAtualizarInfluenciadorProps) => {
  const { mostrarToast } = useContext(MostrarToastContext);

  const sucessoRequisicao = () => {
    mostrarToast(
      "success",
      "Atualização realizada",
      "Usuário atualizado com sucesso!"
    );

    LimparStates([setNome, setImagemAvatar, setPerfil]);
    setTelefone(0);
    setRecarregar();
    setModalAberto(false);
  };

  const erroRequisicao = () => {
    mostrarToast(
      "error",
      "Atualização não realizada",
      "Não foi possível atualizar o usuário."
    );
  };

  const erroPreenchimento = () => {
    mostrarToast(
      "error",
      "Atualização não realizada",
      "Preencha todos os campos e tente novamente."
    );
  };

  function atualizaInfluenciador() {
    console.log(email, emailPrevio);

    if (nome.trim()) {
      const { token, tokenRenovacao } = obterTokens();

      setCarregando(true);
      if (email !== emailPrevio) {
        atualizaEmail();
      }
      putInfluenciador(
        token,
        idCredenciais,
        nome,
        imagemAvatar,
        MascaraNumeroWhatsapp,
        perfil,
        status,
        linkInstagram,
        linkTwitter,
        linkTiktok,
        linkYouTube
      )
        .then(() => {
          sucessoRequisicao();
          setCarregando(false);
        })
        .catch((err) => {
          if (err.message.includes("401")) {
            adicionarAListaEsperaRenovacaoToken(async (token) => {
              if (email !== emailPrevio) {
                atualizaEmail();
              }
              await putInfluenciador(
                token,
                idCredenciais,
                nome,
                imagemAvatar,
                MascaraNumeroWhatsapp,
                perfil,
                status,
                linkInstagram,
                linkTwitter,
                linkTiktok,
                linkYouTube
              )
                .then(() => {
                  sucessoRequisicao();
                })
                .catch(() => {
                  erroRequisicao();
                  setCarregando(false);
                });
            }, setCarregando);
          } else {
            erroRequisicao();
          }
        });
    } else {
      erroPreenchimento();
    }
  }

  function atualizaEmail() {
    if (nome.trim()) {
      const { token, tokenRenovacao } = obterTokens();

      setCarregando(true);

      editarEmailDoUsuario(token, idCredenciais, email)
        .then(() => {
          // sucessoRequisicao();
          setRecarregar();
          setCarregando(false);
        })
        .catch((err) => {
          if (err.message.includes("401")) {
            adicionarAListaEsperaRenovacaoToken(async (token) => {
              await editarEmailDoUsuario(token, idCredenciais, email)
                .then(() => {
                  // sucessoRequisicao();
                  setRecarregar();
                })
                .catch((err) => {
                  erroRequisicao();
                });
            }, setCarregando);
          } else {
            erroRequisicao();

            setCarregando(false);
          }
        });
    } else {
      erroPreenchimento();
    }
  }

  function fazMascaraTelefone(telefone: string) {
    MascaraTelefone(telefone, setTelefone, setMascaraNumeroWhatsapp);
  }

  function fazConverteImgPara64(event: any) {
    converteImgPara64(event, setImagemAvatar);
  }

  return {
    atualizaEmail,
    atualizaInfluenciador,
    fazMascaraTelefone,
    converteImgPara64,
  };
};

import { useContext } from "react";

import { postAgencia } from "../../../services/agencia";
import { LimparStates } from "../../../utils/LimparStates";
import { obterTokens } from "../../../utils/LocalStorage";
import { MostrarToastContext } from "../../../context/MostrarToast";

interface FunctionsBotaoCadastroAgenciaProps {
  nome: string;
  setNome: React.Dispatch<React.SetStateAction<string>>;
  logotipo: string;
  setLogotipo: React.Dispatch<React.SetStateAction<string>>;
  setCarregando: React.Dispatch<boolean>;
  setRecarregar: React.Dispatch<React.SetStateAction<boolean>>;
  botaoAbertoAgencia: boolean;
  setBotaoAbertoAgencia: React.Dispatch<React.SetStateAction<boolean>>;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FunctionsBotaoCadastroAgencia = ({
  logotipo,
  setLogotipo,
  nome,
  setNome,
  setCarregando,
  setRecarregar,
  setBotaoAbertoAgencia,
  adicionarAListaEsperaRenovacaoToken,
}: FunctionsBotaoCadastroAgenciaProps) => {
  const { mostrarToast } = useContext(MostrarToastContext);
  const sucessoRequisicao = () => {
    mostrarToast(
      "success",
      "Cadastro realizado",
      "Agencia cadastrada com sucesso!"
    );
    LimparStates([setNome, setLogotipo]);
    setRecarregar((recarregar) => !recarregar);
  };

  const erroRequisicao = () => {
    mostrarToast(
      "error",
      "Cadastro não realizado",
      "Não foi possível cadastrar a Agencia."
    );
  };

  const erroAgenciaSemNome = () => {
    setCarregando(false);
    setTimeout(() => setBotaoAbertoAgencia(false), 1000);
    mostrarToast(
      "error",
      "Cadastro não realizado",
      "Não é possível cadastrar uma Agência sem nome."
    );
  };

  const salvarAgenciaButton = () => {
    setCarregando(true);
    const { token } = obterTokens();

    nome !== ""
      ? postAgencia(token, { nome, logotipo })
          .then(() => {
            sucessoRequisicao();
            setTimeout(() => setBotaoAbertoAgencia(false), 1000);
            setCarregando(false);
          })
          .catch((err) => {
            console.log(err.message);
            if (err.message.includes("401")) {
              adicionarAListaEsperaRenovacaoToken(async (token) => {
                await postAgencia(token, { nome, logotipo })
                  .then(() => {
                    sucessoRequisicao();
                    setTimeout(() => setBotaoAbertoAgencia(false), 1000);
                  })
                  .catch(() => {
                    erroRequisicao();
                  });
              }, setCarregando);
            } else {
              erroRequisicao();
              setCarregando(false);
            }
          })
      : erroAgenciaSemNome();
  };

  return {
    salvarAgenciaButton,
  };
};

import { useContext } from "react";

import { putUsuarioNetcos } from "../../../services/usuarioNetcos";
import { converteImgPara64 } from "../../../utils/ConverteImgPara64";
import { LimparStates } from "../../../utils/LimparStates";
import { obterTokens } from "../../../utils/LocalStorage";
import { MascaraTelefone } from "../../../utils/MascaraTelefone";

import { MostrarToastContext } from "../../../context/MostrarToast";

export interface FuncoesAtualizarUsuarioNetcosProps {
  id: string;
  nome: string;
  setNome: React.Dispatch<React.SetStateAction<string>>;
  telefone: number;
  setTelefone: React.Dispatch<React.SetStateAction<number>>;
  imagemAvatar: string | null;
  setImagemAvatar: React.Dispatch<React.SetStateAction<string>>;
  status: string;
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>;
  MascaraNumeroWhatsapp: string;
  setMascaraNumeroWhatsapp: React.Dispatch<React.SetStateAction<string>>;
  setModalAberto?: React.Dispatch<boolean>;
  setRecarregar?: () => void;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FuncoesAtualizacaoUsuarioNetcos = ({
  id,
  nome,
  setNome,
  telefone,
  setTelefone,
  imagemAvatar,
  setImagemAvatar,
  status,
  setCarregando,
  adicionarAListaEsperaRenovacaoToken,
  setModalAberto,
  setRecarregar,
  MascaraNumeroWhatsapp,
  setMascaraNumeroWhatsapp,
}: FuncoesAtualizarUsuarioNetcosProps) => {
  const { mostrarToast } = useContext(MostrarToastContext);

  const sucessoRequisicao = () => {
    mostrarToast(
      "success",
      "Atualização realizada",
      "Usuário atualizado com sucesso!"
    );

    LimparStates([setNome, setImagemAvatar, setMascaraNumeroWhatsapp]);
    setTelefone(0);
    setModalAberto && setModalAberto(false);
    setRecarregar && setRecarregar();
  };

  const erroRequisicao = () => {
    mostrarToast(
      "error",
      "Atualização não realizada",
      "Não foi possível atualizar o usuário."
    );
  };

  const erroPreenchimento = () => {
    mostrarToast(
      "error",
      "Atualização não realizada",
      "Preencha todos o campos e tente novamente."
    );
  };

  function atualizarUsuarioNetcos() {
    if (nome.trim() && telefone) {
      const { token, tokenRenovacao } = obterTokens();

      setCarregando(true);
      putUsuarioNetcos(token,
        {
          id,
          nome,
          imagemAvatar,
          telefone: MascaraNumeroWhatsapp,
          status
        })
        .then(() => {
          sucessoRequisicao();
          setCarregando(false);
        })
        .catch((err) => {
          if (err.message.includes("401")) {
            adicionarAListaEsperaRenovacaoToken(async (token) => {
              await putUsuarioNetcos(
                token,
                {
                  id,
                  nome,
                  imagemAvatar,
                  telefone: MascaraNumeroWhatsapp,
                  status
                }
              )
                .then(() => {
                  sucessoRequisicao();
                })
                .catch(() => {
                  erroRequisicao();
                });
            }, setCarregando);
          } else {
            erroRequisicao();
            setCarregando(false);
          }
        });
    } else {
      erroPreenchimento();
    }
  }

  function confirmarAtualizacao(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    atualizarUsuarioNetcos();
  }

  function fazConverteImgPara64(event: any) {
    converteImgPara64(event, setImagemAvatar);
  }

  function fazMascaraTelefone(telefone: string) {
    MascaraTelefone(telefone, setTelefone, setMascaraNumeroWhatsapp);
  }

  return {
    atualizarUsuarioNetcos,
    confirmarAtualizacao,
    fazConverteImgPara64,
    fazMascaraTelefone,
  };
};

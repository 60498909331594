import { useContext } from "react";

import { MostrarToastContext } from "../../../context/MostrarToast";
import { Prints } from "../../../services/publicacao";
import { putTarefa } from "../../../services/tarefas";
import { getConverterImagemUrlParaBase64 } from "../../../services/converterImagem";
import { obterTokens } from "../../../utils/LocalStorage";

interface FunctionsDeletarPrintSentimentoProps {
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>;
  setRecarregar: React.Dispatch<React.SetStateAction<boolean>>;
  printSentimento: Prints[];
  setPrintsSentimento: React.Dispatch<React.SetStateAction<Prints[]>>;
  indicePrint: number;
  setIndicePrint: React.Dispatch<React.SetStateAction<number>>;
  idCampanha: string;
  idTarefa: string;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FunctionsDeletarPrintSentimento = ({
  setCarregando,
  printSentimento,
  setPrintsSentimento,
  indicePrint,
  setIndicePrint,
  setRecarregar,
  idCampanha,
  idTarefa,
  adicionarAListaEsperaRenovacaoToken,
}: FunctionsDeletarPrintSentimentoProps) => {
  const { mostrarToast } = useContext(MostrarToastContext);

  const sucessoRequisicao = () => {
    setRecarregar((e) => !e);
    mostrarToast(
      "success",
      "Remoção realizada",
      "Remoção realizada com sucesso!"
    );
  };

  const erroRequisicao = () => {
    mostrarToast(
      "error",
      "Remoção não realizada",
      "Não foi possível realizar a remoção."
    );
  };

  function deletarPrintRepercussao() {
    const { token, tokenRenovacao } = obterTokens();
    let printsAntigos = printSentimento;

    setCarregando(true);
    setTimeout(async () => {
      let transformarArrayParaEnviar = printsAntigos.map(async function (item) {
        return {
          idUploader: item.idUploader,
          dataUpload: item.dataUpload,
          sentimento: item.sentimento,
          imagem: item.urlImagem,
        };
      });

      const arrayFinalizada = await Promise.all(transformarArrayParaEnviar);

      const imagemParaRemocao = arrayFinalizada[indicePrint];

      arrayFinalizada.splice(indicePrint, 1);

      setPrintsSentimento(arrayFinalizada);
      setIndicePrint(0);
      putTarefa(token, idCampanha, idTarefa, [imagemParaRemocao])
        .then(() => {
          sucessoRequisicao();
          setCarregando(false);
        })
        .catch((err) => {
          if (err.message.includes("401")) {
            adicionarAListaEsperaRenovacaoToken(async (token) => {
              await putTarefa(token, idCampanha, idTarefa, [imagemParaRemocao])
                .then(() => {
                  sucessoRequisicao();
                })
                .catch((err) => {
                  erroRequisicao();
                });
            }, setCarregando);
          } else {
            erroRequisicao();
            setCarregando(false);
          }
        });
    }, 1000);
  }

  async function converterImagemParaBase64(urlImagem: string): Promise<string> {
    var data = "";
    await getConverterImagemUrlParaBase64(urlImagem)
      .then((res) => {
        data = res.data.base64;
      })
      .catch((e) => {
        setCarregando(false);
        erroRequisicao();
        data = e.name;
      });
    return data;
  }

  return {
    deletarPrintRepercussao,
  };
};

import { Input } from "../../../../Input";

interface InputMetricaProps {
  nomeMetrica: string
  metrica: number;
  setMetrica: React.Dispatch<React.SetStateAction<number>>;
  disabled: boolean;
}

export const InputMetrica = ({nomeMetrica, metrica, setMetrica, disabled} : InputMetricaProps) => {
  return (
    <div>
    { }
    <Input
      type="number"
      min={0}
      required
      disabled={disabled}
      placeholder={nomeMetrica}
      value={metrica !== 0 ? metrica : undefined}
      onChange={(e) => {
        setMetrica(e.target.valueAsNumber);
      }}
    />
  </div>
  )
}
import { useContext } from "react";

import { postUsuarioAgencia } from "../../../services/usuarioAgencia";
import { converteImgPara64 } from "../../../utils/ConverteImgPara64";
import { LimparStates } from "../../../utils/LimparStates";
import { obterTokens } from "../../../utils/LocalStorage";
import { MascaraTelefone } from "../../../utils/MascaraTelefone";

import { MostrarToastContext } from "../../../context/MostrarToast";

interface FunctionsModalCadastroUsuarioAgenciaProps {
  nome: string;
  setNome: React.Dispatch<React.SetStateAction<string>>;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  imagemAvatar: string;
  setImagemAvatar: React.Dispatch<React.SetStateAction<string>>;
  telefone: number;
  setTelefone: React.Dispatch<React.SetStateAction<number>>;
  idAgencia: string;
  setIdAgencia: React.Dispatch<React.SetStateAction<string>>;
  enviarNotificacaoDeCadastro: boolean;
  setEnviarNotificacaoDeCadastro: React.Dispatch<React.SetStateAction<boolean>>;
  setCarregando: React.Dispatch<boolean>;
  MascaraNumeroWhatsapp: string;
  setMascaraNumeroWhatsapp: React.Dispatch<React.SetStateAction<string>>;
  myToast: React.MutableRefObject<any>;
  setModalAberto: React.Dispatch<boolean>;
  setRecarregar: React.Dispatch<React.SetStateAction<boolean>>;
  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FunctionsModalCadastroUsuarioAgencia = ({
  nome,
  setNome,
  email,
  setEmail,
  imagemAvatar,
  setImagemAvatar,
  telefone,
  setTelefone,
  idAgencia,
  setIdAgencia,
  enviarNotificacaoDeCadastro,
  setEnviarNotificacaoDeCadastro,
  myToast,
  setModalAberto,
  setRecarregar,
  MascaraNumeroWhatsapp,
  setMascaraNumeroWhatsapp,
  setCarregando,
  adicionarAListaEsperaRenovacaoToken,
}: FunctionsModalCadastroUsuarioAgenciaProps) => {
  const { mostrarToast } = useContext(MostrarToastContext);

  const sucessoRequisicao = () => {
    mostrarToast(
      "success",
      "cadastro realizado",
      "Usuário cadastrado com sucesso!"
    );
    setRecarregar((e) => !e);
    setModalAberto(false);
  };

  const erroRequisicao = (mensagemApi: string) => {
    let mensagem: string = "";

    switch (mensagemApi) {
      case "User name '' is invalid, can only contain letters or digits.":
        mensagem = "Preencha todos os campos e tente novamente.";
        break;
      case "Este e-mail já está sendo usado.":
        mensagem = "Email já cadastrado no sistema";
        break;
      case "O nome é obrigatório.":
        mensagem = "O nome é obrigatório.";
        break;

      default:
        mensagem =
          "Não foi possível cadastrar usuário, tente novamente mais tarde.";
    }

    showToast("error", "Cadastro não realizado", mensagem);
  };

  const salvarUsuarioAgencia = () => {
    setCarregando(true);

    const { token, tokenRenovacao } = obterTokens();
    postUsuarioAgencia(
      token,
      {
        nome,
        email,
        imagemAvatar,
        telefone: MascaraNumeroWhatsapp,
        idAgencia,
        status: "Pendente",
      },
      enviarNotificacaoDeCadastro
    )
      .then(() => {
        sucessoRequisicao();
        LimparStates([
          setIdAgencia,
          setEmail,
          setNome,
          setImagemAvatar,
          setMascaraNumeroWhatsapp,
        ]);
        setTelefone(0);
        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await postUsuarioAgencia(
              token,
              {
                nome,
                email,
                imagemAvatar,
                telefone: MascaraNumeroWhatsapp,
                idAgencia,
                status: "Pendente",
              },
              enviarNotificacaoDeCadastro
            )
              .then(() => {
                LimparStates([
                  setIdAgencia,
                  setEmail,
                  setNome,
                  setImagemAvatar,
                  setMascaraNumeroWhatsapp,
                ]);
                setTelefone(0);
                sucessoRequisicao();
              })
              .catch(() => {
                erroRequisicao(err.response.data.message);
              });
          }, setCarregando);
        } else {
          erroRequisicao(err.response.data.message);
          setCarregando(false);
        }
      });
  };

  function fazConverteImgPara64(event: any) {
    converteImgPara64(event, setImagemAvatar);
  }

  function fazMascaraTelefone(telefone: string) {
    MascaraTelefone(telefone, setTelefone, setMascaraNumeroWhatsapp);
  }

  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string
  ) => {
    myToast.current.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  return {
    converteImgPara64,
    fazMascaraTelefone,
    salvarUsuarioAgencia,
    showToast,
  };
};

import { Input } from "../../../Input";
import { InputMetrica } from "./inputs/InputMetrica";

interface InstagramFeedProps {
  aprovar: boolean;
  desabilitarCampos: boolean;

  likes: number;
  setLikes: React.Dispatch<React.SetStateAction<number>>;
  comentarios: number;
  setComentarios: React.Dispatch<React.SetStateAction<number>>;
  compartilhamentos: number;
  setCompartilhamentos: React.Dispatch<React.SetStateAction<number>>;
  salvos: number;
  setSalvos: React.Dispatch<React.SetStateAction<number>>;
  alcance: number;
  setAlcance: React.Dispatch<React.SetStateAction<number>>;
  impressoes: number;
  setImpressoes: React.Dispatch<React.SetStateAction<number>>;
  visitasPerfil: number;
  setVisitasPerfil: React.Dispatch<React.SetStateAction<number>>;
  visitasAoPerfil: number;
  setVisitasAoPerfil: React.Dispatch<React.SetStateAction<number>>;

}
export const InstagramFeed = ({
  aprovar,
  desabilitarCampos,
  likes,
  setLikes,
  comentarios,
  setComentarios,
  compartilhamentos,
  setCompartilhamentos,
  salvos,
  setSalvos,
  alcance,
  setAlcance,
  impressoes,
  setImpressoes,
  visitasPerfil,
  setVisitasAoPerfil,
  visitasAoPerfil,
  setVisitasPerfil

}: InstagramFeedProps) => {
  const listaMetricas = [
    { nome: "Likes", metrica: { valor: likes, dispatch: setLikes } },
    { nome: "Comentários", metrica: { valor: comentarios, dispatch: setComentarios } },
    { nome: "Compartilhamentos", metrica: {valor: compartilhamentos, dispatch: setCompartilhamentos } },
    { nome: "Salvos", metrica: {valor: salvos, dispatch: setSalvos } },
    { nome: "Alcance", metrica: {valor: alcance, dispatch: setAlcance } },
    { nome: "Impressões", metrica: {valor: impressoes, dispatch: setImpressoes } },
  ]

  return (
    <>
      {listaMetricas.map((item, index) => {
        return (
          <InputMetrica
            key={index}
            nomeMetrica={item.nome}
            metrica={item.metrica.valor!}
            setMetrica={item.metrica.dispatch!}
            disabled={aprovar || desabilitarCampos}
          />
        )
      })} 

      <div>
        <Input
          type="number"
          min={0}
          required
          disabled={aprovar}
          placeholder="Visitas ao Perfil"
          value={
            visitasAoPerfil !== 0
              ? visitasAoPerfil
              : visitasPerfil !== 0
                ? visitasPerfil
                : undefined
          }
          onChange={(e) => {
            setVisitasAoPerfil(e.target.valueAsNumber);
            setVisitasPerfil(e.target.valueAsNumber);
          }}
        />
      </div>
    </>
  )
}
import { tipoPublicacaoProps } from "../../../services/campanha";
import { postOcr } from "../../../services/ocr";
import {
  Prints,
  deleteMidiaDeEvidencia,
  deletePrintsdeMetrica,
  putPublicacao,
  putPublicacaoProps,
} from "../../../services/publicacao";

import { converteTempoCompletoParaSegundo } from "../../../utils/ConversorTempo";
import { LimparStates } from "../../../utils/LimparStates";
import { obterCredenciais, obterTokens } from "../../../utils/LocalStorage";
import {
  convertTimeString,
  converterStringParaNumberContendoVirgula,
  converterStringParaNumberMilhar,
} from "../../../utils/filtrarDigitos";

export interface FuncoesModalEditarTarefaProps {
  idCampanha: string;
  id: string;
  urlPublicacao: string;
  alcance: number;
  setAlcance: React.Dispatch<React.SetStateAction<number>>;
  impressoes: number;
  setImpressoes: React.Dispatch<React.SetStateAction<number>>;
  likes: number;
  setLikes: React.Dispatch<React.SetStateAction<number>>;
  comentarios: number;
  setComentarios: React.Dispatch<React.SetStateAction<number>>;
  compartilhamentos: number;
  setCompartilhamentos: React.Dispatch<React.SetStateAction<number>>;
  views: number;
  setViews: React.Dispatch<React.SetStateAction<number>>;
  salvos: number;
  setSalvos: React.Dispatch<React.SetStateAction<number>>;
  visitasAoPerfil: number;
  setVisitasAoPerfil: React.Dispatch<React.SetStateAction<number>>;
  visitasPerfil: number;
  setVisitasPerfil: React.Dispatch<React.SetStateAction<number>>;
  cliquesLink: number;
  setCliquesLink: React.Dispatch<React.SetStateAction<number>>;
  viewsTotais: number;
  setViewsTotais: React.Dispatch<React.SetStateAction<number>>;
  viewsTotal: number;
  setViewsTotal: React.Dispatch<React.SetStateAction<number>>;
  tempoReproducaoHora: number;
  setTempoReproducaoHora: React.Dispatch<React.SetStateAction<number>>;
  tempoReproducaoMinuto: number;
  setTempoReproducaoMinuto: React.Dispatch<React.SetStateAction<number>>;
  tempoReproducaoSegundo: number;
  setTempoReproducaoSegundo: React.Dispatch<React.SetStateAction<number>>;
  engajamentos: number;
  setEngajamentos: React.Dispatch<React.SetStateAction<number>>;
  favoritos: number;
  setFavoritos: React.Dispatch<React.SetStateAction<number>>;
  respostas: number;
  setRespostas: React.Dispatch<React.SetStateAction<number>>;
  retweets: number;
  setRetweets: React.Dispatch<React.SetStateAction<number>>;
  dislikes: number;
  setDislikes: React.Dispatch<React.SetStateAction<number>>;
  taxaAprovacao: number;
  setTaxaAprovacao: React.Dispatch<React.SetStateAction<number>>;
  espectadores: number;
  setEspectadores: React.Dispatch<React.SetStateAction<number>>;
  cliquesStickers: number;
  setCliquesStickers: React.Dispatch<React.SetStateAction<number>>;
  cliquesHashtag: number;
  setCliquesHashtag: React.Dispatch<React.SetStateAction<number>>;
  detailExpands: number;
  setDetailExpands: React.Dispatch<React.SetStateAction<number>>;
  quantidadeReproducoes: number;
  setQuantidadeReproducoes: React.Dispatch<React.SetStateAction<number>>;
  setCarregando: React.Dispatch<React.SetStateAction<boolean>>;

  myToast: React.MutableRefObject<any>;
  printsDeMetrica: Prints[];
  setModalAberto: React.Dispatch<React.SetStateAction<boolean>>;
  setRecarregar: React.Dispatch<React.SetStateAction<boolean>>;
  setURLRequired: React.Dispatch<React.SetStateAction<boolean>>;
  setPrintMetricaRequired: React.Dispatch<React.SetStateAction<boolean>>;
  comentariosNetcos: string;
  tipoPublicacao: tipoPublicacaoProps;
  dataPublicacao: string;
  setAbrirModalComentario: React.Dispatch<React.SetStateAction<boolean>>;
  midiaDaPublicacao: Prints[];
  setCarregandoBuscarMetricas: React.Dispatch<React.SetStateAction<boolean>>;
  nomeArquivo: string;

  setTempoReproducaoTiktokHora: React.Dispatch<React.SetStateAction<number>>;
  tempoReproducaoTiktokHora: number;
  setTempoReproducaoTiktokMinuto: React.Dispatch<React.SetStateAction<number>>;
  tempoReproducaoTiktokMinuto: number;
  setTempoReproducaoTiktokSegundo: React.Dispatch<React.SetStateAction<number>>;
  tempoReproducaoTiktokSegundo: number;

  urlMidiaDaPublicacaoASerApagada: string;
  urlPrintsDeMetricaASerApagada: string;
  fezOptin: boolean;

  //Carrossel
  setMidiaDaPublicacao: React.Dispatch<React.SetStateAction<Prints[]>>;
  midiaDaPublicacaoCarrossel: Prints[];
  setPrintsDeMetrica: React.Dispatch<React.SetStateAction<Prints[]>>;
  printsDeMetricaCarrossel: Prints[];

  setCarregarDelet: React.Dispatch<React.SetStateAction<boolean>>;

  adicionarAListaEsperaRenovacaoToken: (
    callback: (token: string) => Promise<void>,
    setCarregando:
      | Function
      | React.Dispatch<React.SetStateAction<boolean>>
      | React.Dispatch<boolean>
  ) => void;
}

export const FuncoesModalEditarTarefa = ({
  setCarregarDelet,
  midiaDaPublicacaoCarrossel,
  printsDeMetricaCarrossel,
  setMidiaDaPublicacao,
  setPrintsDeMetrica,
  urlMidiaDaPublicacaoASerApagada,
  urlPrintsDeMetricaASerApagada,
  idCampanha,
  id,
  urlPublicacao,
  alcance,
  setAlcance,
  impressoes,
  setImpressoes,
  likes,
  setLikes,
  comentarios,
  setComentarios,
  compartilhamentos,
  setCompartilhamentos,
  views,
  setViews,
  salvos,
  setSalvos,
  visitasAoPerfil,
  setVisitasAoPerfil,
  visitasPerfil,
  setVisitasPerfil,
  cliquesLink,
  setCliquesLink,
  viewsTotais,
  setViewsTotais,
  viewsTotal,
  setViewsTotal,
  tempoReproducaoHora,
  setTempoReproducaoHora,
  tempoReproducaoMinuto,
  setTempoReproducaoMinuto,
  tempoReproducaoSegundo,
  setTempoReproducaoSegundo,
  engajamentos,
  setEngajamentos,
  favoritos,
  setFavoritos,
  respostas,
  setRespostas,
  retweets,
  setRetweets,
  dislikes,
  setDislikes,
  espectadores,
  setEspectadores,
  cliquesStickers,
  setCliquesStickers,
  cliquesHashtag,
  setCliquesHashtag,
  detailExpands,
  setDetailExpands,
  quantidadeReproducoes,
  setQuantidadeReproducoes,
  setCarregando,
  adicionarAListaEsperaRenovacaoToken,
  myToast,
  printsDeMetrica,
  setModalAberto,
  setRecarregar,
  setURLRequired,
  setPrintMetricaRequired,
  comentariosNetcos,
  tipoPublicacao,
  dataPublicacao,
  setAbrirModalComentario,
  midiaDaPublicacao,
  setCarregandoBuscarMetricas,
  nomeArquivo,
  setTempoReproducaoTiktokHora,
  tempoReproducaoTiktokHora,
  setTempoReproducaoTiktokMinuto,
  tempoReproducaoTiktokMinuto,
  setTempoReproducaoTiktokSegundo,
  tempoReproducaoTiktokSegundo,
  fezOptin,
}: FuncoesModalEditarTarefaProps) => {
  const converteImgPara64 = (event: any, array: Prints[]) => {
    var result: string = "";
    let id = obterCredenciais().credenciais.id;

    var reader = new FileReader();
    reader.readAsDataURL(event);
    reader.onerror = function (error) {
      console.log("Error FileReader: ", error);
    };
    reader.onloadend = function () {
      result = reader.result ? reader.result.toString() : "";
      array.push({
        idUploader: id,
        midia: result,
        dataUpload: new Date().toISOString(),
      });
    };
  };

  function mapeaObjeto(printsPut: File[]) {
    let arrayPrintSentimentos: Prints[] = [];
    for (let i = 0; i < printsPut.length; i++) {
      converteImgPara64(printsPut[i], arrayPrintSentimentos);
    }

    return arrayPrintSentimentos;
  }

  function buscaMetricasPeloPrint() {
    setCarregandoBuscarMetricas(true);

    let { token, tokenRenovacao } = obterTokens();

    const publicacaoPreenchida: putPublicacaoProps = {
      idCampanha: idCampanha,
      publicacao: {
        id: id,
        printsDeMetrica:
          printsDeMetrica.length === 0 || !printsDeMetrica[0].midia
            ? null
            : printsDeMetrica,
        // printsDeSentimento: printsDeSentimento,
      },
    };

    const arrayMidia: string[] = [];

    printsDeMetrica.map((print) => {
      arrayMidia.push(print.midia!);
    });

    postOcr(token, arrayMidia, tipoPublicacao)
      .then((res) => {
        let metricas = JSON.parse(res.data.metricas);

        switch (tipoPublicacao) {
          case "PublicacaoInstagramStories":
            setAlcance(Number(metricas.Alcance.replaceAll(".", "").replaceAll(",", "")));
            setImpressoes(Number(metricas.Impressoes.replaceAll(".", "").replaceAll(",", "")));
            setLikes(Number(metricas.Likes.replaceAll(".", "").replaceAll(",", "")));
            setCompartilhamentos(Number(metricas.Compartilhamentos.replaceAll(".", "").replaceAll(",", "")));
            setCliquesLink(Number(metricas.CliquesLink.replaceAll(".", "").replaceAll(",", "")));
            setVisitasAoPerfil(Number(metricas.VisitasPerfil.replaceAll(".", "").replaceAll(",", "")));
            setVisitasPerfil(Number(metricas.VisitasPerfil.replaceAll(".", "").replaceAll(",", "")));
            setCliquesStickers(Number(metricas.CliquesStickers.replaceAll(".", "").replaceAll(",", "")));
            setRespostas(Number(metricas.Respostas.replaceAll(".", "").replaceAll(",", "")));
            break;
          case "PublicacaoInstagramFeed":
            setAlcance(Number(metricas.Alcance.replaceAll(".", "").replaceAll(",", "")));
            setImpressoes(Number(metricas.Impressoes.replaceAll(".", "").replaceAll(",", "")));
            setComentarios(Number(metricas.Comentarios.replaceAll(".", "").replaceAll(",", "")));
            setCompartilhamentos(Number(metricas.Compartilhamentos.replaceAll(".", "").replaceAll(",", "")));
            setLikes(Number(metricas.Likes.replaceAll(".", "").replaceAll(",", "")));
            setSalvos(Number(metricas.Salvos.replaceAll(".", "").replaceAll(",", "")));
            setVisitasPerfil(Number(metricas.VisitasAoPerfil.replaceAll(".", "").replaceAll(",", "")));
            setVisitasAoPerfil(Number(metricas.VisitasAoPerfil.replaceAll(".", "").replaceAll(",", "")));
            break;
          case "PublicacaoInstagramReels":
            setAlcance(Number(metricas.Alcance.replaceAll(".", "").replaceAll(",", "")));
            setQuantidadeReproducoes(Number(metricas.QuantidadeReproducoes.replaceAll(".", "").replaceAll(",", "")));
            setComentarios(Number(metricas.Comentarios.replaceAll(".", "").replaceAll(",", "")));
            setCompartilhamentos(Number(metricas.Compartilhamentos.replaceAll(".", "").replaceAll(",", "")));
            setLikes(Number(metricas.Likes.replaceAll(".", "").replaceAll(",", "")));
            setSalvos(Number(metricas.Salvos.replaceAll(".", "").replaceAll(",", "")));
            break;
          case "PublicacaoTikTok":
            setEspectadores(converterStringParaNumberContendoVirgula(metricas.Espectadores));
            setLikes(converterStringParaNumberMilhar(metricas.Likes));
            setViews(converterStringParaNumberMilhar(metricas.Views));
            setSalvos(converterStringParaNumberContendoVirgula(metricas.Salvos));
            setComentarios(Number(metricas.Comentarios.replaceAll(".", "").replaceAll(",", "")));
            setCompartilhamentos(Number(metricas.Compartilhamentos.replaceAll(".", "").replaceAll(",", "")));
            break;
        }
        if (tipoPublicacao === "PublicacaoTikTok") {
          const tempoConvertido = convertTimeString(metricas.TempoReproducao);
          setTempoReproducaoTiktokHora(tempoConvertido.horas);
          setTempoReproducaoTiktokMinuto(tempoConvertido.minutos);
          setTempoReproducaoTiktokSegundo(tempoConvertido.segundos);
        }

        showToast(
          "success",
          "Métricas Buscadas",
          "Métricas Buscadas com sucesso!"
        );
        setCarregandoBuscarMetricas(false);
      })
      .catch((err) => {
        if (err.message.includes('401')) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            postOcr(token, arrayMidia, tipoPublicacao)
              .then((res1) => {
                let metricas1 = JSON.parse(res1.data.metricas);
                switch (tipoPublicacao) {
                  case "PublicacaoInstagramStories":
                    setAlcance(Number(metricas1.Alcance.replaceAll(".", "").replaceAll(",", "")));
                    setImpressoes(
                      Number(metricas1.Impressoes.replaceAll(".", "").replaceAll(",", ""))
                    );
                    setLikes(
                      Number(metricas1.Likes.replaceAll(".", "").replaceAll(",", ""))
                    );
                    setCompartilhamentos(
                      Number(metricas1.Compartilhamentos.replaceAll(".", "").replaceAll(",", ""))
                    );
                    setCliquesLink(
                      Number(metricas1.CliquesLink.replaceAll(".", "").replaceAll(",", ""))
                    );
                    setVisitasAoPerfil(
                      Number(metricas1.VisitasPerfil.replaceAll(".", "").replaceAll(",", ""))
                    );
                    setVisitasPerfil(
                      Number(metricas1.VisitasAoPerfil.replaceAll(".", "").replaceAll(",", ""))
                    );
                    setCliquesStickers(
                      Number(metricas1.CliquesStickers.replaceAll(".", "").replaceAll(",", ""))
                    );
                    setRespostas(
                      Number(metricas1.Respostas.replaceAll(".", "").replaceAll(",", ""))
                    );

                    break;
                  case "PublicacaoInstagramFeed":
                    setAlcance(Number(metricas1.Alcance.replaceAll(".", "").replaceAll(",", "")));
                    setImpressoes(
                      Number(metricas1.Impressoes.replaceAll(".", "").replaceAll(",", ""))
                    );
                    setComentarios(
                      Number(metricas1.Comentarios.replaceAll(".", "").replaceAll(",", ""))
                    );
                    setCompartilhamentos(
                      Number(metricas1.Compartilhamentos.replaceAll(".", "").replaceAll(",", ""))
                    );
                    setLikes(Number(metricas1.Likes.replaceAll(".", "").replaceAll(",", "")));
                    setSalvos(Number(metricas1.Salvos.replaceAll(".", "").replaceAll(",", "")));
                    setVisitasPerfil(
                      Number(metricas1.VisitasAoPerfil.replaceAll(".", "").replaceAll(",", ""))
                    );
                    setVisitasAoPerfil(
                      Number(metricas1.VisitasAoPerfil.replaceAll(".", "").replaceAll(",", ""))
                    );
                    break;
                  case "PublicacaoInstagramReels":
                    setAlcance(Number(metricas1.Alcance.replaceAll(".", "").replaceAll(",", "")));
                    setQuantidadeReproducoes(
                      Number(
                        metricas1.QuantidadeReproducoes.replaceAll(".", "").replaceAll(",", "")
                      )
                    );
                    setComentarios(
                      Number(metricas1.Comentarios.replaceAll(".", "").replaceAll(",", ""))
                    );
                    setCompartilhamentos(
                      Number(metricas1.Compartilhamentos.replaceAll(".", "").replaceAll(",", ""))
                    );
                    setLikes(Number(metricas1.Likes.replaceAll(".", "").replaceAll(",", "")));
                    setSalvos(Number(metricas1.Salvos.replaceAll(".", "").replaceAll(",", "")));
                    break;
                  case "PublicacaoTikTok":
                    setEspectadores(
                      converterStringParaNumberContendoVirgula(
                        metricas1.Espectadores
                      )
                    );
                    setLikes(
                      converterStringParaNumberMilhar(metricas1.Likes)
                    );
                    setViews(
                      converterStringParaNumberMilhar(metricas1.Views)
                    );
                    setSalvos(
                      converterStringParaNumberContendoVirgula(
                        metricas1.Salvos
                      )
                    );
                    setComentarios(
                      Number(metricas1.Comentarios.replaceAll(".", "").replaceAll(",", ""))
                    );
                    setCompartilhamentos(
                      Number(metricas1.Compartilhamentos.replaceAll(".", "").replaceAll(",", ""))
                    );
                    break;
                }

                if (tipoPublicacao === "PublicacaoTikTok") {
                  const tempoConvertido = convertTimeString(
                    metricas1.TempoReproducao
                  );
                  setTempoReproducaoTiktokHora(tempoConvertido.horas);
                  setTempoReproducaoTiktokMinuto(tempoConvertido.minutos);
                  setTempoReproducaoTiktokSegundo(tempoConvertido.segundos);
                }

                showToast(
                  "success",
                  "Métricas Buscadas",
                  "Métricas Buscadas com sucesso!"
                );
              })
              .catch((err) => {
                showToast(
                  "error",
                  "Métricas não Buscadas",
                  "Não foi possível buscar as Métricas."
                );
              });
          }, setCarregandoBuscarMetricas);
        } else {
          showToast(
            "error",
            "Métricas não Buscadas",
            "Não foi possível buscar as Métricas."
          );
          setCarregandoBuscarMetricas(false);
        }
      });
  }

  function atualizarPublicacao(
    token: string,
    tokenRenovacao: string,
    publicacaoPreenchida: putPublicacaoProps
  ) {
    setCarregando(true);

    putPublicacao(token, publicacaoPreenchida)
      .then(() => {
        showToast(
          "success",
          "Métricas Inseridas",
          "Métricas Inseridas com sucesso!"
        );
        setCarregando(false);
        setRecarregar((estadoPrevio) => !estadoPrevio);
        setAbrirModalComentario(false);
        setModalAberto(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(async (token) => {
            await putPublicacao(token, publicacaoPreenchida)
              .then(() => {
                showToast(
                  "success",
                  "Métricas Inseridas",
                  "Métricas Inseridas com sucesso!"
                );
                setRecarregar((estadoPrevio) => !estadoPrevio);
                setAbrirModalComentario(false);
                setModalAberto(false);
              })
              .catch(() => {
                showToast(
                  "error",
                  "Métricas não inseridas",
                  "Não foi possível inserir as Métricas."
                );
              });
          }, setCarregando);
        } else {
          showToast(
            "error",
            "Métricas não inseridas",
            "Não foi possível inserir as Métricas."
          );
          setCarregando(false);
        }
      });
  }

  function editarMetricasPublicacaoInfluenciador(
    aprovado: boolean,
    dataInclusaoStories?: string
  ) {
    let tempoReproducaoFinal: number = converteTempoCompletoParaSegundo(
      tempoReproducaoHora,
      tempoReproducaoMinuto,
      tempoReproducaoSegundo
    );
    let tempoReproducaoFinalTikTok: number = converteTempoCompletoParaSegundo(
      tempoReproducaoTiktokHora,
      tempoReproducaoTiktokMinuto,
      tempoReproducaoTiktokSegundo
    );

    let influenciador =
      obterCredenciais().credenciais.perfil[0] === "Influenciador"
        ? true
        : false;

    let token = obterTokens().token;
    let tokenRenovacao = obterTokens().tokenRenovacao;

    const printsMetricas: Prints[] = [...printsDeMetrica];

    const midiasEvidencia: Prints[] = [...midiaDaPublicacao];

    const publicacaoPreenchida: putPublicacaoProps = {
      idCampanha: idCampanha,
      publicacao: {
        id: id,
        dataFinalizacao:
          tipoPublicacao === "PublicacaoInstagramStories" && dataInclusaoStories
            ? new Date(dataInclusaoStories!).toISOString()
            : dataPublicacao
              ? dataPublicacao
              : "1",
        printsDeMetrica:
          printsDeMetrica.length === 0 || !printsDeMetrica[0].midia
            ? null
            : printsDeMetrica,
        urlPublicacao: urlPublicacao,
        midiasDeEvidencia:
          midiaDaPublicacao.length === 0 || !midiaDaPublicacao[0].midia
            ? null
            : midiaDaPublicacao,
        // printsDeSentimento: printsDeSentimento,
        status: influenciador ? "EmAnalise" : aprovado ? "Aprovado" : "Ajustes",
        alcance: alcance,
        impressoes: impressoes,
        likes: likes,
        comentarios: comentarios,
        compartilhamentos: compartilhamentos,
        views: views,
        salvos: salvos,
        visitasAoPerfil: visitasAoPerfil,
        visitasPerfil: visitasPerfil,
        cliquesLink: cliquesLink,
        viewsTotais: viewsTotais,
        viewsTotal: viewsTotal,
        tempoReproducao:
          tipoPublicacao === "PublicacaoTikTok"
            ? tempoReproducaoFinalTikTok
            : tempoReproducaoFinal,
        engajamentos: engajamentos,
        favoritos: favoritos,
        respostas: respostas,
        retweets: retweets,
        dislikes: dislikes,
        taxaAprovacao: (likes / (likes + dislikes)) * 100,
        espectadores: espectadores,
        cliquesStickers: cliquesStickers,
        cliquesHashtag: cliquesHashtag,
        detailExpands: detailExpands,
        quantidadeReproducoes: quantidadeReproducoes,
        comentariosNetcos: comentariosNetcos,
      },
    };

    if (fezOptin) {
      atualizarPublicacao(token, tokenRenovacao, publicacaoPreenchida);
    } else {
      if (printsDeMetrica.length !== 0) {
        setPrintMetricaRequired(false);
        atualizarPublicacao(token, tokenRenovacao, publicacaoPreenchida);
      } else {
        setPrintMetricaRequired(true);
      }
    }
  }

  const sucessoRequisicao = () => {
    showToast("success", "operação realizado", "Print deletado com sucesso!");
  };

  const erroRequisicao = () => {
    showToast(
      "error",
      "operção não realizado",
      "Não foi possível deletar a mídia."
    );
  };

  function deletarPrintsDeMetrica(listaDePrints: Prints[]) {
    setCarregando(true);
    setCarregarDelet(true);
    const { token, tokenRenovacao } = obterTokens();

    deletePrintsdeMetrica(token, idCampanha, urlPrintsDeMetricaASerApagada)
      .then(() => {
        setPrintsDeMetrica((state) =>
          state.filter(
            (print) => print.urlImagem !== urlPrintsDeMetricaASerApagada
          )
        );
        sucessoRequisicao();
        setCarregando(false);
        setTimeout(() => setCarregarDelet(false), 500);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(
            async (token) => {
              await deletePrintsdeMetrica(
                token,
                idCampanha,
                urlPrintsDeMetricaASerApagada
              )
                .then(() => {
                  setPrintsDeMetrica(
                    listaDePrints.filter(
                      (print) =>
                        print.urlImagem !== urlPrintsDeMetricaASerApagada
                    )
                  );
                  sucessoRequisicao();
                })
                .catch(() => {
                  erroRequisicao();
                });
            },
            () => {
              setCarregando(false);
              setTimeout(() => setCarregarDelet(false), 500);
            }
          );
        } else {
          erroRequisicao();
          setCarregando(false);
          setTimeout(() => setCarregarDelet(false), 500);
        }
      });
  }

  function deletarMidiaDeEvidencia(litaDeMidias: Prints[]) {
    setCarregarDelet(true);
    setCarregando(true);
    const { token, tokenRenovacao } = obterTokens();

    deleteMidiaDeEvidencia(token, idCampanha, urlMidiaDaPublicacaoASerApagada)
      .then(() => {
        setMidiaDaPublicacao((state) =>
          state.filter(
            (midia) => midia.urlImagem !== urlMidiaDaPublicacaoASerApagada
          )
        );
        sucessoRequisicao();
        setTimeout(() => setCarregarDelet(false), 500);

        setCarregando(false);
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          adicionarAListaEsperaRenovacaoToken(
            async (token) => {
              await deleteMidiaDeEvidencia(token, idCampanha, nomeArquivo)
                .then(() => {
                  setMidiaDaPublicacao(
                    litaDeMidias.filter(
                      (midia) =>
                        midia.urlImagem !== urlMidiaDaPublicacaoASerApagada
                    )
                  );
                  sucessoRequisicao();
                })
                .catch(() => {
                  erroRequisicao();
                });
            },
            () => {
              setTimeout(() => setCarregarDelet(false), 500);

              setCarregando(false);
            }
          );
        } else {
          erroRequisicao();
          setTimeout(() => setCarregarDelet(false), 500);

          setCarregando(false);
        }
      });
  }

  function limparStatesDeMetricas() {
    switch (tipoPublicacao) {
      case "PublicacaoInstagramStories":
        LimparStates([
          setAlcance,
          setImpressoes,
          setCompartilhamentos,
          setCliquesLink,
          setVisitasAoPerfil,
          setVisitasPerfil,
          setCliquesStickers,
          setRespostas,
          setLikes,
        ]);
        break;
      case "PublicacaoInstagramFeed":
        LimparStates([
          setAlcance,
          setImpressoes,
          setComentarios,
          setCompartilhamentos,
          setLikes,
          setSalvos,
          setVisitasPerfil,
          setVisitasAoPerfil,
        ]);
        break;
      case "PublicacaoInstagramReels":
        LimparStates([
          setAlcance,
          setComentarios,
          setCompartilhamentos,
          setLikes,
          setSalvos,
          setQuantidadeReproducoes,
        ]);
        break;
      case "PublicacaoTikTok":
        LimparStates([
          setComentarios,
          setCompartilhamentos,
          setLikes,
          setSalvos,
          setTempoReproducaoTiktokHora,
          setTempoReproducaoTiktokMinuto,
          setTempoReproducaoTiktokSegundo,
          setViews,
          setEspectadores,
        ]);
        break;
      case "PublicacaoTwitter":
        LimparStates([
          setCliquesLink,
          setComentarios,
          setEngajamentos,
          setFavoritos,
          setImpressoes,
          setRespostas,
          setRetweets,
          setViews,
          setCliquesHashtag,
          setDetailExpands,
        ]);
        break;
      case "PublicacaoYouTube":
        LimparStates([
          setComentarios,
          setDislikes,
          setImpressoes,
          setLikes,
          setTempoReproducaoHora,
          setTempoReproducaoMinuto,
          setTempoReproducaoSegundo,
          setViewsTotal,
          setEspectadores,
        ]);
        break;
      case "PublicacaoYouTubeShorts":
        LimparStates([
          setComentarios,
          setDislikes,
          setImpressoes,
          setLikes,
          setViewsTotais,
          setEspectadores,
        ]);
        break;
    }
  }

  const showToast = (
    severityValue: string,
    summaryValue: string,
    detailValue: string
  ) => {
    myToast.current.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  return {
    editarMetricasPublicacaoInfluenciador,
    mapeaObjeto,
    buscaMetricasPeloPrint,
    deletarPrintsDeMetrica,
    deletarMidiaDeEvidencia,
    limparStatesDeMetricas,
  };
};

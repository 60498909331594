import { useContext, useEffect, useRef, useState } from "react";

import styles from "./styles.module.css";

import { FunctionVisaoGeral } from "./functions";

import { CredenciaisContext } from "../../../../context/CredenciaisContext";
import { campanha } from "../../../../services/campanha";
import { PublicacoesProps } from "../../../../services/publicacao";

import { CampoBigNumbers } from "../CampoBigNumbers";
import { CampoInformacoes } from "../CampoInformacoes";
import { ModalAdicaoDeComentariosCampanha } from "../../../../components/Modais/ModalAdicaoDeComentariosCampanha";
import { GraficoAdsOrganicos } from "../../../../components/Graficos/GraficoAdsOrganicos";
import { GraficoInfluenciadoresDaCampanha } from "../../../../components/Graficos/GraficoInfluenciadoresDaCampanha";
import { Button } from "../../../../components/Botoes/Button";

import { Toast } from "primereact/toast";
import { Editor } from "primereact/editor";
import { GraficoComparativoRedeSocial } from "../../../../components/Graficos/GraficoComparativoRedeSocial";
import { GraficoVisaoGeral } from "../../../../components/Graficos/GraficoVisaoGeral";
import { ModalExpandirComentario } from "../../../../components/Modais/ModalExpandirComentario";

import AnimateHeight from "react-animate-height";
import { TabelaComparativoRedesSociais } from "../../../../components/Tabelas/TabelaComparativoRedesSociais";
import { GraficoVisaoGeralPorRedeSocial } from "../../../../components/Graficos/GraficoVisaoGeralPorRedeSocial";

interface VisaoGeralProps {
  campanha: campanha;
  recarregar: () => void;
}

export const VisaoGeral = ({ campanha, recarregar }: VisaoGeralProps) => {
  const [modalComentario, setModalComentario] = useState<boolean>(false);
  const [modalLeituraDeComentario, setModalLeituraDeComentario] =
    useState<boolean>(false);
  const perfil = useContext(CredenciaisContext).credenciais.perfil[0];
  const [publicacoes, setPublicacoes] = useState<PublicacoesProps[]>([]);

  const [comentarioNetCosAberto, setComentarioNetCosAberto] =
    useState<boolean>(false);

  const [tamanhoCaixaComentario, setTamanhoCaixaComentario] =
    useState<number>(60);
  const [linhasCaixaComentario, setLinhasCaixaComentario] = useState<number>(0);
  const [comentarioCampanha, setComentarioCampanha] = useState<string>(
    campanha?.comentarios ? campanha?.comentarios : ""
  );

  const myToast = useRef<any>(null);

  useEffect(() => {
    let publicacoesTemporarias: PublicacoesProps[] = [];
    campanha.tarefas.map((tarefa) => {
      publicacoesTemporarias.push(...tarefa.publicacoes);
    });
    setPublicacoes(publicacoesTemporarias);
  }, []);

  const { tamanhoCaixaComentarioNetcos } = FunctionVisaoGeral();

  useEffect(() => {
    const { linhas, tamanho } =
      tamanhoCaixaComentarioNetcos(comentarioCampanha);
    setTamanhoCaixaComentario(tamanho);
    setLinhasCaixaComentario(linhas);
  }, [comentarioCampanha]);

  return (
    <div className={styles.pagina}>
      {(perfil === "UsuarioMarca" || perfil === "UsuarioAgencia") &&
        comentarioCampanha == "" ? (
        <></>
      ) : (
        <CampoInformacoes
          titulo={
            <div className={styles.tituloComentario}>
              Avaliação NetCos
              {(perfil === "UsuarioNetcos" || perfil === "Administrador") && (
                <Button
                  importancia="secundario"
                  iconLeft={
                    <i
                      className="pi pi-pencil"
                      style={{ fontSize: "1.6rem" }}
                    ></i>
                  }
                  title="Editar"
                  onClick={() => setModalComentario(true)}
                />
              )}
            </div>
          }
        >
          <div className={styles.campoComentarioCompleto}>
            {comentarioCampanha ? (
              <div className={styles.campoComentario}>
                <div className={styles.editorContainer}>
                  <AnimateHeight
                    duration={500}
                    height={
                      comentarioNetCosAberto ? "auto" : tamanhoCaixaComentario
                    }
                  >
                    <Editor
                      className={styles.editorComentario}
                      value={comentarioCampanha}
                      readOnly
                      headerTemplate={<></>}
                    />
                  </AnimateHeight>
                  {!comentarioNetCosAberto &&
                    comentarioCampanha &&
                    linhasCaixaComentario > 10 ? (
                    <div className={styles.editorGradient} />
                  ) : (
                    <></>
                  )}
                </div>

                {perfil !== "UsuarioNetcos" && perfil !== "Administrador" && (
                  <span
                    className={styles.botaoLerMais}
                    onClick={() => {
                      setComentarioNetCosAberto(true);
                      // setModalComentario(true);
                    }}
                  >
                    {comentarioCampanha && comentarioNetCosAberto
                      ? "Ler menos"
                      : "Ler mais"}
                  </span>
                )}
              </div>
            ) : (
              <p>Nenhum comentário feito sobre a campanha</p>
            )}
            {(perfil === "UsuarioNetcos" || perfil === "Administrador") && (
              <div className={styles.containerBotaoLerMais}>
                <Button
                  importancia="secundario"
                  title={
                    comentarioCampanha && comentarioNetCosAberto
                      ? "Ler menos"
                      : "Ler mais"
                  }
                  onClick={() => {
                    setComentarioNetCosAberto((state) =>
                      state ? false : true
                    );
                    //  setModalLeituraDeComentario(true)
                  }}
                />
              </div>
            )}
          </div>
        </CampoInformacoes>
      )}

      <CampoBigNumbers
        impressoes={campanha?.numeroImpressoes}
        taxaEngajamento={campanha?.mediaTaxaEngajamento}
        engajamentoTotal={campanha?.engajamentoTotal}
        custoPorMilImpressoes={campanha?.custoPorMilImpressoes}
        custoEngajamento={campanha?.custoEngajamento}
      />

      <CampoInformacoes titulo="Resultados Gerais">
        <GraficoVisaoGeral publicacoes={publicacoes} />
      </CampoInformacoes>

      <GraficoInfluenciadoresDaCampanha campanha={campanha} />
      <CampoInformacoes titulo="Comparativo Redes Sociais">
        <GraficoVisaoGeralPorRedeSocial publicacoes={publicacoes} />
        {/* <GraficoComparativoRedeSocial publicacoes={publicacoes} /> */}
        <CampoInformacoes subtitulo="Análise entre os formatos das redes sociais:">
          <div className={styles.campoTabelaComparativoRedesSociais}>
            <TabelaComparativoRedesSociais campanha={campanha} />
          </div>
        </CampoInformacoes>
      </CampoInformacoes>

      {(((perfil === "UsuarioAgencia" || perfil === "UsuarioMarca") &&
        campanha.taxaEngajamentoOutrasPublicacoes !== null &&
        campanha.taxaEngajamentoOutrasPublicacoes !== 0) ||
        perfil === "Administrador" ||
        perfil === "UsuarioNetcos") && (
          <CampoInformacoes
            titulo="Comparativo Ads e Orgânico"
            subtitulo="Análise por taxa de engajamento"
          >
            <GraficoAdsOrganicos campanha={campanha} recarregar={recarregar} />
          </CampoInformacoes>
        )}

      <Toast ref={myToast} className="custom-toast" />
      {modalComentario && (
        <ModalAdicaoDeComentariosCampanha
          perfilUsuario={perfil}
          comentario={campanha?.comentarios ? campanha.comentarios : ""}
          modalAberto={modalComentario}
          setModalAberto={setModalComentario}
          id={campanha?.id}
          recarregar={recarregar}
        />
      )}

      <ModalExpandirComentario
        comentario={campanha?.comentarios ? campanha.comentarios : ""}
        modalAberto={modalLeituraDeComentario}
        setModalAberto={setModalLeituraDeComentario}
        id={campanha?.id}
      />
    </div>
  );
};

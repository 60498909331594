import { PublicacoesProps } from "../../../services/publicacao";
import MascaraGrafico from "../../../utils/MascaraRotulosGraficos";
import { somaMetrica } from "../../../utils/somaMetrica";
import { format } from "../../../utils/MascaraNumero/format";

export interface basicDataProps {
  labels: string[];
  datasets: datasetsProps[];
}
interface datasetsProps {
  backgroundColor?: (context: any) => (CanvasGradient | undefined)[] | undefined;
  data: number[];
  pointStyle?: string | null;
  borderColor: string;
  borderWidth?: number;
  datalabels?: datalabelsProps;
  label?: string;
  fill?: boolean;
  tension?: number;
}

interface datalabelsProps {
  align?: (context: any) => string;
  anchor?: (context: any) => string;
}

interface FuncoesGraficoVisaoGeralProps {
  setBasicData: React.Dispatch<React.SetStateAction<basicDataProps>>;

  setAlcanceTotal: React.Dispatch<React.SetStateAction<number>>;
  setEngajamentoTotal: React.Dispatch<React.SetStateAction<number>>;
  setImpressoesTotal: React.Dispatch<React.SetStateAction<number>>;
  setComentariosTotal: React.Dispatch<React.SetStateAction<number>>;
  setCompartilhamentosTotal: React.Dispatch<React.SetStateAction<number>>;
  setLikesTotal: React.Dispatch<React.SetStateAction<number>>;
  setDislikesTotal: React.Dispatch<React.SetStateAction<number>>;
  setSalvosTotal: React.Dispatch<React.SetStateAction<number>>;
  setRetweetsTotal: React.Dispatch<React.SetStateAction<number>>;
  setFavoritosTotal: React.Dispatch<React.SetStateAction<number>>;
  setTwitter: React.Dispatch<React.SetStateAction<number>>;
  setTwitterExiste: React.Dispatch<React.SetStateAction<boolean>>;
  setInstagram: React.Dispatch<React.SetStateAction<number>>;
  setInstagramExiste: React.Dispatch<React.SetStateAction<boolean>>;
  setInstagramFeedExiste: React.Dispatch<React.SetStateAction<boolean>>;
  setInstagramReelsExiste: React.Dispatch<React.SetStateAction<boolean>>;
  setTiktok: React.Dispatch<React.SetStateAction<number>>;
  setTiktokExiste: React.Dispatch<React.SetStateAction<boolean>>;
  setYoutube: React.Dispatch<React.SetStateAction<number>>;
  setYoutubeExiste: React.Dispatch<React.SetStateAction<boolean>>;
  setBasicOptions: React.Dispatch<any>;
}

export const FuncoesGraficoVisaoGeral = ({
  setBasicData,
  setAlcanceTotal,
  setComentariosTotal,
  setCompartilhamentosTotal,
  setDislikesTotal,
  setEngajamentoTotal,
  setFavoritosTotal,
  setImpressoesTotal,
  setInstagram,
  setLikesTotal,
  setRetweetsTotal,
  setSalvosTotal,
  setTiktok,
  setTwitter,
  setYoutube,
  setInstagramExiste,
  setInstagramFeedExiste,
  setInstagramReelsExiste,
  setTiktokExiste,
  setTwitterExiste,
  setYoutubeExiste,
  setBasicOptions,
}: FuncoesGraficoVisaoGeralProps) => {

  const larguraTela = window.screen.width;
  const telaTablet = larguraTela <= 968;
  const telaMobile = larguraTela <= 420;

  function setaDados(publicacoes: PublicacoesProps[]) {
    let dataArrayTotal = setaDadosTotais(publicacoes);
    let dataArrayTwitter = setaDadosTwitter(publicacoes);
    let dataArrayInsta = setaDadosInsta(publicacoes);
    let dataArrayYoutube = setaDadosYoutube(publicacoes);
    let dataArrayTiktok = setaDadosTiktok(publicacoes);

    let divGraficoVisaoGeral = document.getElementById("divGraficoVisaoGeral");
    let graficoVisaoGeral = divGraficoVisaoGeral?.firstElementChild;
    graficoVisaoGeral?.setAttribute("id", "graficoVisaoGeral");

    var graficoVisaoGeralId = <HTMLCanvasElement>(
      document.getElementById("graficoVisaoGeral")
    );
    var graficoVisãoGeralContexto = graficoVisaoGeralId.getContext("2d");

    const datasets = [
      {
        type: 'bar',
        backgroundColor: (context: any) => {
          if (!context.element.y || !context.element.height) {
            return
          }

          const { y, height } = context.element

          const gradienteImpressoes = graficoVisãoGeralContexto!.createLinearGradient(
            0,
            y - (height / 2),
            0,
            y + (height / 2),
          );
          gradienteImpressoes?.addColorStop(0.1, "#ef8ada");
          gradienteImpressoes?.addColorStop(1, "#fc887b");

          const gradienteAlcance = graficoVisãoGeralContexto?.createLinearGradient(
            0,
            y - (height / 2),
            0,
            y + (height / 2),
          );
          gradienteAlcance?.addColorStop(0.1, "#fc887b");
          gradienteAlcance?.addColorStop(1, "#ffdd95");

          const gradienteEngajamento = graficoVisãoGeralContexto?.createLinearGradient(
            0,
            y - (height / 2),
            0,
            y + (height / 2),
          );
          gradienteEngajamento?.addColorStop(0.1, "#ffdd95");
          gradienteEngajamento?.addColorStop(1, "#d0e6a5");

          const gradienteLikes = graficoVisãoGeralContexto?.createLinearGradient(
            0,
            y - (height / 2),
            0,
            y + (height / 2),
          );
          gradienteLikes?.addColorStop(0.1, "#d0e6a5");
          gradienteLikes?.addColorStop(1, "#86e3ce");

          const gradienteComentarios = graficoVisãoGeralContexto?.createLinearGradient(
            0,
            y - (height / 2),
            0,
            y + (height / 2),
          );
          gradienteComentarios?.addColorStop(0.1, "#86e3ce");
          gradienteComentarios?.addColorStop(1, "#89d5f8");

          return [gradienteImpressoes, gradienteAlcance, gradienteEngajamento, gradienteLikes, gradienteComentarios]
        },
        data: dataArrayTotal,
        shrinkAnchor: 'none',
        borderColor: "#00000000",
        borderWidth: 0,
        datalabels: {
          align: function (context: any) {
            return (context.dataIndex === 0 || context.dataIndex === 1) ? "center" : "end";
          },
          anchor: function (context: any) {
            return (context.dataIndex === 0 || context.dataIndex === 1) ? "center" : "end";
          },
          offset: 12,
        },
      }
    ];

    const instagramExiste = publicacoes.find(
      (publicacao) =>
        publicacao.tipo === "PublicacaoInstagramFeed" ||
        publicacao.tipo === "PublicacaoInstagramReels" ||
        publicacao.tipo === "PublicacaoInstagramStories"
    );

    const instagramFeedExiste = publicacoes.find(
      (publicacao) =>
        publicacao.tipo === "PublicacaoInstagramFeed"
    );

    const instagramReelsExiste = publicacoes.find(
      (publicacao) =>
        publicacao.tipo === "PublicacaoInstagramReels"
    );

    const tiktokExiste = publicacoes.find(
      (publicacao) => publicacao.tipo === "PublicacaoTikTok"
    );

    const twitterExiste = publicacoes.find(
      (publicacao) => publicacao.tipo === "PublicacaoTwitter"
    );

    const youtubeExiste = publicacoes.find(
      (publicacao) =>
        publicacao.tipo === "PublicacaoYouTube" ||
        publicacao.tipo === "PublicacaoYouTubeShorts"
    );

    if (instagramExiste) { setInstagramExiste(true) }
    if (instagramFeedExiste) { setInstagramFeedExiste(true) }
    if (instagramReelsExiste) { setInstagramReelsExiste(true) }
    if (tiktokExiste) { setTiktokExiste(true) }
    if (twitterExiste) { setTwitterExiste(true) }
    if (youtubeExiste) { setYoutubeExiste(true) }

    let basicData = {
      labels: ["Impressões", "Alcance", "Engajamento", "Likes", "Comentários"],
      datasets,
    };

    setBasicData(basicData);
  }

  const getLightTheme = () => {
    let basicOptions = {
      indexAxis: 'y',
      barPercentage: 1,
      categoryPercentage: 1,
      maintainAspectRatio: false,
      aspectRatio: 1.2,
      plugins: {
        legend: {
          display: false,
          labels: {
            pointStyleWidth: 40,
            usePointStyle: true,
          },
        },
        tooltip: {
          callbacks: {
            label: (context: any) => {
              return `${format(
                "#.###.##0,#0",
                context.raw[1]
              ).replace(",00", "")}`;
            },
          },
        },
        datalabels: {
          color: function (context: any) {
            return (context.dataIndex === 0 || context.dataIndex === 1) ? "#fff"
              : context.dataIndex === 2 ? "#A7A152"
                : context.dataIndex === 3 ? "#92B7A1"
                  : "#5BB7BC"
          },
          font: {
            weight: 'bold',
            size: telaMobile ? 14 : 18,
          },
          formatter: function (value: number[]) {
            return MascaraGrafico(value[1]);
          },
        },
      },
      scales: {
        y: {
          display: true,
          ticks: {
            color: "#495057",
          },
          grid: {
            display: true,
            color: "#ebedef",
            z: 1,
          },
        },
        x: {
          display: false,
          ticks: {
            align: 'center',
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
      },
    };
    setBasicOptions(basicOptions);

    return {
      basicOptions,
    };
  };

  function setaDadosTwitter(publicacoes: PublicacoesProps[]) {
    let dataArray: number[] = [];
    let somaAlcance: number = 0;
    let somaImpressoes: number = 0;
    let somaEngajamento: number = 0;
    let somaLikes: number = 0;
    let somaComentarios: number = 0;
    let somaTwitter: number = 0;

    publicacoes.map((publicacao) => {
      if (
        publicacao.tipo === "PublicacaoTwitter" &&
        publicacao.status === "Aprovado"
      ) {
        somaImpressoes = somaMetrica(somaImpressoes, publicacao.impressoes);

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.engajamento);

        somaComentarios = somaMetrica(somaComentarios, publicacao.comentarios);

        somaTwitter = somaTwitter + 1;
      }
    });

    setTwitter(somaTwitter);

    dataArray = [
      somaImpressoes,
      somaAlcance,
      somaEngajamento,
      somaLikes,
      somaComentarios,
    ];

    return dataArray;
  }

  function setaDadosInsta(publicacoes: PublicacoesProps[]) {
    let dataArray: number[] = [];
    let somaAlcance: number = 0;
    let somaImpressoes: number = 0;
    let somaEngajamento: number = 0;
    let somaLikes: number = 0;
    let somaComentarios: number = 0;
    let somaInstagram: number = 0;

    publicacoes.map((publicacao) => {
      if (
        (publicacao.tipo === "PublicacaoInstagramReels" ||
          publicacao.tipo === "PublicacaoInstagramFeed" ||
          publicacao.tipo === "PublicacaoInstagramStories") &&
        publicacao.status === "Aprovado"
      ) {
        somaAlcance = somaMetrica(somaAlcance, publicacao.alcance);

        somaImpressoes = somaMetrica(somaImpressoes, publicacao.impressoes);

        somaImpressoes = somaMetrica(
          somaImpressoes,
          publicacao.quantidadeReproducoes
        );

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.likes);

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.comentarios);

        somaEngajamento = somaMetrica(
          somaEngajamento,
          publicacao.compartilhamentos
        );

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.salvos);

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.respostas);

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.cliquesLink);

        somaEngajamento = somaMetrica(
          somaEngajamento,
          publicacao.cliquesStickers
        );

        somaLikes = somaMetrica(somaLikes, publicacao.likes);

        somaComentarios = somaMetrica(somaComentarios, publicacao.comentarios);

        somaInstagram = somaInstagram + 1;
      }
    });

    setInstagram(somaInstagram);

    dataArray = [
      somaImpressoes,
      somaAlcance,
      somaEngajamento,
      somaLikes,
      somaComentarios,
    ];
    return dataArray;
  }

  function setaDadosTiktok(publicacoes: PublicacoesProps[]) {
    let dataArray: number[] = [];
    let somaAlcance: number = 0;
    let somaImpressoes: number = 0;
    let somaEngajamento: number = 0;
    let somaLikes: number = 0;
    let somaComentarios: number = 0;
    let somaTiktok: number = 0;

    publicacoes.map((publicacao) => {
      if (
        publicacao.tipo === "PublicacaoTikTok" &&
        publicacao.status === "Aprovado"
      ) {
        somaAlcance = somaMetrica(somaAlcance, publicacao.espectadores);

        somaImpressoes = somaMetrica(somaImpressoes, publicacao.views);

        somaImpressoes = somaMetrica(somaImpressoes, publicacao.viewsTotal);

        somaImpressoes = somaMetrica(somaImpressoes, publicacao.viewsTotais);

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.likes);

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.comentarios);

        somaEngajamento = somaMetrica(
          somaEngajamento,
          publicacao.compartilhamentos
        );

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.salvos);

        somaLikes = somaMetrica(somaLikes, publicacao.likes);

        somaComentarios = somaMetrica(somaComentarios, publicacao.comentarios);

        somaTiktok = somaTiktok + 1;
      }
    });

    setTiktok(somaTiktok);

    dataArray = [
      somaImpressoes,
      somaAlcance,
      somaEngajamento,
      somaLikes,
      somaComentarios,
    ];

    return dataArray;
  }

  function setaDadosYoutube(publicacoes: PublicacoesProps[]) {
    let dataArray: number[] = [];
    let somaAlcance: number = 0;
    let somaImpressoes: number = 0;
    let somaEngajamento: number = 0;
    let somaLikes: number = 0;
    let somaComentarios: number = 0;
    let totalYoutube: number = 0;

    publicacoes.map((publicacao) => {
      if (
        (publicacao.tipo === "PublicacaoYouTube" ||
          publicacao.tipo === "PublicacaoYouTubeShorts") &&
        publicacao.status === "Aprovado"
      ) {
        somaAlcance = somaMetrica(somaAlcance, publicacao.espectadores);

        somaImpressoes = somaMetrica(somaImpressoes, publicacao.impressoes);

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.likes);

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.comentarios);

        somaLikes = somaMetrica(somaLikes, publicacao.likes);

        somaComentarios = somaMetrica(somaComentarios, publicacao.comentarios);

        totalYoutube = totalYoutube + 1;
      }
    });

    setYoutube(totalYoutube);

    dataArray = [
      somaImpressoes,
      somaAlcance,
      somaEngajamento,
      somaLikes,
      somaComentarios,
    ];

    return dataArray;
  }

  function setaDadosTotais(publicacoes: PublicacoesProps[]) {
    let totalArray: any = [];
    let somaAlcance: number = 0;
    let somaImpressoes: number = 0;
    let somaEngajamento: number = 0;
    let somaLikes: number = 0;
    let somaComentarios: number = 0;
    let somaFavoritos: number = 0;
    let somaCompartilhamentos: number = 0;
    let somaDislikes: number = 0;
    let somaRetweets: number = 0;
    let somaSalvos: number = 0;

    let totalAprovados: number = 0;

    publicacoes.map((publicacao) => {
      if (publicacao.status === "Aprovado") {
        totalAprovados = totalAprovados + 1;
        somaAlcance = somaMetrica(somaAlcance, publicacao.alcance);

        somaAlcance = somaMetrica(somaAlcance, publicacao.espectadores);

        somaImpressoes = somaMetrica(somaImpressoes, publicacao.impressoes);

        somaImpressoes = somaMetrica(
          somaImpressoes,
          publicacao.quantidadeReproducoes
        );

        somaImpressoes = somaMetrica(somaImpressoes);

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.likes);

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.comentarios);

        somaEngajamento = somaMetrica(
          somaEngajamento,
          publicacao.compartilhamentos
        );

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.salvos);

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.respostas);

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.cliquesLink);

        somaEngajamento = somaMetrica(
          somaEngajamento,
          publicacao.cliquesStickers
        );

        somaEngajamento = somaMetrica(somaEngajamento, publicacao.engajamento);

        somaLikes = somaMetrica(somaLikes, publicacao.likes);

        somaComentarios = somaMetrica(somaComentarios, publicacao.comentarios);

        somaFavoritos = somaMetrica(somaFavoritos, publicacao.favoritos);

        somaCompartilhamentos = somaMetrica(
          somaCompartilhamentos,
          publicacao.compartilhamentos
        );

        somaDislikes = somaMetrica(somaDislikes, publicacao.dislikes);

        somaRetweets = somaMetrica(somaRetweets, publicacao.retweets);

        somaSalvos = somaMetrica(somaSalvos, publicacao.salvos);
      }
    });

    setAlcanceTotal(somaAlcance);
    setImpressoesTotal(somaImpressoes);
    setEngajamentoTotal(somaEngajamento);
    setLikesTotal(somaLikes);
    setComentariosTotal(somaComentarios);
    setFavoritosTotal(somaFavoritos);
    setCompartilhamentosTotal(somaCompartilhamentos);
    setDislikesTotal(somaDislikes);
    setRetweetsTotal(somaRetweets);
    setSalvosTotal(somaSalvos);

    let impressoes = [(somaImpressoes * (-1)), somaImpressoes]
    let alcance = [(somaAlcance * (-1)), somaAlcance]
    let engajamento = [(somaEngajamento * (-1)), somaEngajamento]
    let likes = [(somaLikes * (-1)), somaLikes]
    let comentarios = [(somaComentarios * (-1)), somaComentarios]

    totalArray = [impressoes, alcance, engajamento, likes, comentarios];
    // totalArray = [somaImpressoes, somaAlcance, somaEngajamento, somaLikes, somaComentarios];

    return totalArray;
  }

  return {
    setaDados,
    getLightTheme,
  };
};
